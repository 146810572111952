import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import terminal from './components/terminal.vue';
import ruleConfing from './components/rule_config.vue';

formCreate.component('terminal', terminal);
formCreate.component('ruleConfing', ruleConfing);
export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'addForm',
      buttonText: {
        submit: '保存',
        close: '取消',
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  mounted() {

  },

  methods: {
    formComplete() {

    },
    setRule(item) {
      const v = item;
      console.log(v);
      if (item.field === 'agreeDateRange') {
        item.props = {
          ...item.props,
          type: 'daterange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd',
        };
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              agreeDateRange: e,
            });
          },
        };
      }
      if (item.field === 'signDateRange') {
        item.props = {
          ...item.props,
          type: 'daterange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd',
        };
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              signDateRange: e,
            });
          },
        };
      }
      if (item.field === 'object') {
        item.props = {
          ...item.props,
        };
        v.on = {
          ...v.on,
          change: (e) => {
            const terminalList = this.getRule('terminalList');
            terminalList.props = {
              ...terminalList.props,
              objType: e,
            };
          },
        };
      }
      return v;
    },

    submit() {
      const formData = this.getFormData();
      const terminalData = this.fApi.el('terminalList');
      const ruleData = this.fApi.el('rule');
      console.log('formData');
      console.log(formData);
      console.log('terminalData');
      console.log(terminalData);
      console.log('ruleData');
      console.log(ruleData);
      if (!formData) {
        return false;
      }
      formData.signType = formData.signType.join(',');
      formData.signLevel = formData.signLevel.join(',');
      if (formData.object === '1') {
        if (terminalData.orgList.length === 0) {
          this.$message.error('请选择组织');
          return;
        }
      } else if (formData.object === '2') {
        if (terminalData.areaList.length === 0) {
          this.$message.error('请选择行政区域');
          return;
        }
      } else if (formData.object === '3') {
        if (terminalData.terminalList.length === 0) {
          this.$message.error('请选择终端');
          return;
        }
      } else if (formData.object === '4') {
        if (terminalData.coumserList.length === 0) {
          this.$message.error('请选择终经销商');
          return;
        }
      }

      // 随单搭赠
      if (ruleData.activeIndex === '1') {
        if (ruleData.prolist.length === 0) {
          this.$message.error('请选随单搭赠产品');
          return;
        }
      }
      // 包量政策
      if (ruleData.activeIndex === '2') {
        if (ruleData.quantityProlist.length === 0) {
          this.$message.error('请选包量政策产品');
          return;
        }
      }
      if (ruleData.activeIndex === '3') {
        if (ruleData.displyJtList.length === 0) {
          this.$message.error('请选择陈列政策产品');
          return;
        }
      }
      const params = {
        ...formData,
        agreeStartTime: formData.agreeDateRange[0], // 有效开始时间
        agreeEndTime: formData.agreeDateRange[1], // 有效结束时间
        signStartTime: formData.signDateRange[0], // 签署开始时间
        signEndTime: formData.signDateRange[1], // 签署结束时间
        type: ruleData.activeIndex, // 1，随单搭赠，2，包量政策，3，成列政策
        agreeTemplateUrl: '', // 模版url
        products: [], // 产品列表
        displayReqVo: {
          object: formData.object,
          ladderCode: '', // 阶梯编码
          timeRadio: '', // 固定，自定
          dateMode: '', // 日期周期：1，每周，2，每月
          limitNum: 0, // 上传次数
          pileSampleUrl: [], // 堆头图
          sheetNum: 0, // 单次上传次数
          passRate: '', // 通过率
          shelfSampleUrl: [], // 货架图
          uploadReqVos: [],
          detailReqVos: [],

        },
        followReqVo: {
          object: formData.object,
          content: '', // 搭赠分利规则描述
          mode: ruleData.standard, // 1，单阶梯增，2多阶梯赠单阶梯：多阶梯
          detailReqVos: [],
        },
        objects: [],

        quantifyReqVo: {
          object: formData.object,
          ladderCode: '', // 阶梯编码
          combineAssess: ruleData.check, // 是否合并考核 0,1
          detailReqVos: [],
        },
      };
      // 处理协议对象数据
      if (formData.object === '1') {
        params.objects = terminalData.orgList;
      } else if (formData.object === '2') {
        const subData = [];
        terminalData.areaList.forEach((v) => {
          subData.push({
            code: v.cityCode,
            name: `${v.provinceName}-${v.cityName}`,
          });
        });
        params.objects = subData;
      } else if (formData.object === '3') {
        params.objects = terminalData.terminalList;
      } else if (formData.object === '4') {
        params.objects = terminalData.coumserList;
      }
      // 随单搭赠
      if (ruleData.activeIndex === '1') {
        ruleData.prolist.forEach((v) => {
          params.products.push({
            productCode: v.productCode,
            productName: v.productName,
            productLevelCode: v.productLevelCode,
            productLevelName: v.productLevelName,
          });
        });
        // 搭赠计算标准
        ruleData.standList.forEach((item, index) => {
          params.followReqVo.detailReqVos.push({
            rewardNum: item.rewardNum, // 元，瓶数
            rewardType: item.reward, // 奖励类型：1，红包，2，实物
            standardNum: item.num, // 入库满
            giftProductVos: item.standproduct,
          });
        });
        // 搭赠分利规则描述
        params.followReqVo.content = ruleData.textarea;
      }
      // 包量政策
      if (ruleData.activeIndex === '2') {
        ruleData.quantityProlist.forEach((v) => {
          params.products.push({
            productCode: v.productCode,
            productName: v.productName,
            productLevelCode: v.productLevelCode,
            productLevelName: v.productLevelName,
          });
        });
        params.quantifyReqVo.ladderCode = ruleData.policyList[0].ladderCode;
        ruleData.jtList.forEach((bv) => {
          params.quantifyReqVo.detailReqVos.push({
            ratio: bv.ladderTable[0].ratio, // 阶梯比例
            rewardName: bv.ladderTable[0].rewardName, // 奖励名称
            rewardPeriods: bv.ladderTable[0].rewardPeriod, // 多值","分隔，按年，按季，按半年,
            rewardType: bv.ladderTable[0].rewardType, // 奖励类型  1,现金，2.实物
            giftProductVos: bv.ladderTable[0].giftProductVos,
            rebateVos: ruleData.check === '1' ? bv.hbList : bv.quantityProlist,
          });
        });
      }
      // 陈列政策
      if (ruleData.activeIndex === '3') {
        ruleData.displayProlist.forEach((v) => {
          params.products.push({
            productCode: v.productCode,
            productName: v.productName,
            productLevelCode: v.productLevelCode,
            productLevelName: v.productLevelName,
          });
        });
        if (ruleData.timeRadio === '1') {
          ruleData.timeList.forEach((ditem, dindex) => {
            params.displayReqVo.uploadReqVos.push({
              indexNo: dindex,
              startNum: ditem.start,
              endNum: ditem.end,
            });
          });
        }
        if (ruleData.timeRadio === '2') {
          ruleData.selectDay.forEach((ditem, dindex) => {
            params.displayReqVo.uploadReqVos.push({
              indexNo: dindex,
              startNum: ditem,
              endNum: '0',
            });
          });
        }

        ruleData.displayladderTable.forEach((jitem, jindex) => {
          params.displayReqVo.detailReqVos.push({
            ratio: jitem.ratio, // 达成比例
            rewardName: jitem.rewardName, // 奖励名称
            allYearMoney: jitem.allYearMoney, // 季度达成数量
            halfYearMoney: jitem.halfYearMoney, // 季度达成数量
            quarterMoney: jitem.quarterMoney, // 季度达成数量
            rewardPeriods: jitem.rewardPeriod, // 周期 多值","分隔，按年，按季，按半年
            rewardType: jitem.rewardType, // 奖励类型
            giftProductVos: jitem.giftProductVos,
          });
        });
        params.displayReqVo.ladderCode = ruleData.displyJtList[0].ladderCode;
        params.displayReqVo.uploadConfig = ruleData.timeRadio;
        params.displayReqVo.dateMode = ruleData.selectcycleVal;
        params.displayReqVo.limitNum = ruleData.limitNum;
        params.displayReqVo.pileSampleUrl = ruleData.pileSampleUrl;
        params.displayReqVo.sheetNum = ruleData.sheetNum;
        params.displayReqVo.shelfSampleUrl = ruleData.shelfSampleUrl;
        params.displayReqVo.passRate = parseFloat(ruleData.passRate);
      }
      console.log(params);
      request.post('/cps/cpsagreetemplate/getAgreeTemplateUrl', params).then((res) => {
        console.log(res);
        if (res.success) {
          params.agreeTemplateUrl = res.message;
          request.post('/cps/cpsagreetemplate/save', params).then((sres) => {
            console.log(sres);
            if (sres.success) {
              this.$message({
                type: 'success',
                message: '操作成功',
              });
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
        }
      });
    },
    // 获取数据字典
    async getDictSelect() {
      const params = [
        'sfa_stock_check_execute',
      ];
      await request.post('/mdm/mdmdictdata/batchDictSelect', params).then((res) => {
        if (res.success) {
          res.result.sfa_stock_check_execute.map((v) => {
            this.optionsList.push({
              ...v,
              value: v.dictCode,
              label: v.dictValue,
            });
            return v;
          });
        }
      });
    },
  },
};
