var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.object === "1",
              expression: "object === '1'",
            },
          ],
          staticClass: "mar10",
        },
        [
          _c(
            "el-col",
            { attrs: { offset: 1 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.objects, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "组织编码", prop: "code", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "组织名称", prop: "name", align: "center" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.object === "2",
              expression: "object === '2'",
            },
          ],
          staticClass: "mar10",
        },
        [_c("el-col", { attrs: { offset: 1 } }, [_vm._v(" 区域未定 ")])],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.object === "3",
              expression: "object === '3'",
            },
          ],
          staticClass: "mar10",
        },
        [
          _c(
            "el-col",
            { attrs: { offset: 1 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.objects, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "终端编码", prop: "code", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "终端名称", prop: "name", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "终端等级", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.terminalType[row.terminalLevel]) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.object === "4",
              expression: "object === '4'",
            },
          ],
          staticClass: "mar10",
        },
        [
          _c(
            "el-col",
            { attrs: { offset: 1 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.objects, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商编码",
                      prop: "code",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商名称",
                      prop: "name",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticStyle: {
                "font-size": "16px",
                height: "20px",
                "line-height": "20px",
                "font-weight": "600",
                margin: "20px 0",
              },
              attrs: { span: 24 },
            },
            [_vm._v("分利规则配置")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabs" },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.ruleType,
                callback: function ($$v) {
                  _vm.ruleType = $$v
                },
                expression: "ruleType",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "随单搭赠", disabled: "", name: "1" } },
                [
                  _c("div", { staticClass: "proList margin10" }, [
                    _c("div", { staticClass: "pro_label" }, [_vm._v("产品")]),
                    _c(
                      "div",
                      {
                        staticClass: "cost-select el-textarea el-input--small",
                        staticStyle: { "min-height": "32px" },
                      },
                      _vm._l(_vm.products, function (item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticStyle: { "margin-right": "10px" },
                          },
                          [_vm._v(_vm._s(item.productName || ""))]
                        )
                      }),
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "stand margin10" },
                    [
                      _c(
                        "el-form",
                        { ref: "form", attrs: { "label-width": "100px" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "搭赠计算标准" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.obj.mode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.obj, "mode", $$v)
                                    },
                                    expression: "obj.mode",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("单阶梯赠"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("多阶梯赠"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "stand_list" }, [
                    _c(
                      "div",
                      { staticClass: "stand_list_item" },
                      [
                        _c(
                          "el-row",
                          _vm._l(_vm.obj.details, function (item) {
                            return _c(
                              "el-col",
                              {
                                key: item.id,
                                staticClass: "stand_item",
                                attrs: { span: 24 },
                              },
                              [
                                _c("div", [_vm._v("入库满")]),
                                _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        size: "mini",
                                        disabled: "",
                                        inline: true,
                                      },
                                      model: {
                                        value: item.standardNum,
                                        callback: function ($$v) {
                                          _vm.$set(item, "standardNum", $$v)
                                        },
                                        expression: "item.standardNum",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", [_vm._v("箱,奖励")]),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择",
                                          inline: true,
                                          size: "mini",
                                          disabled: "",
                                        },
                                        model: {
                                          value: item.rewardType,
                                          callback: function ($$v) {
                                            _vm.$set(item, "rewardType", $$v)
                                          },
                                          expression: "item.rewardType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.rewardOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                item.rewardType == 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cost-select el-textarea el-input--small",
                                        staticStyle: {
                                          "min-height": "32px",
                                          width: "400px",
                                          "word-wrap": "break-word",
                                        },
                                      },
                                      [
                                        item.giftProductVos.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "flex-wrap": "wrap",
                                                },
                                              },
                                              _vm._l(
                                                item.giftProductVos,
                                                function (pitem, pindex) {
                                                  return _c(
                                                    "el-tag",
                                                    { key: pindex },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          pitem.productName ||
                                                            ""
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                item.rewardType == 1
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            inline: true,
                                            size: "mini",
                                            disabled: "",
                                          },
                                          model: {
                                            value: item.rewardNum,
                                            callback: function ($$v) {
                                              _vm.$set(item, "rewardNum", $$v)
                                            },
                                            expression: "item.rewardNum",
                                          },
                                        }),
                                        _vm._v("元 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.rewardType == 2
                                  ? _c(
                                      "div",
                                      [
                                        _vm._v(" 各 "),
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            inline: true,
                                            size: "mini",
                                            disabled: "",
                                          },
                                          model: {
                                            value: item.rewardNum,
                                            callback: function ($$v) {
                                              _vm.$set(item, "rewardNum", $$v)
                                            },
                                            expression: "item.rewardNum",
                                          },
                                        }),
                                        _vm._v("瓶 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "stand_icon" }),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "margin10" },
                    [
                      _c("div", [_vm._v("搭赠分利规则描述")]),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 10,
                          placeholder: "请输入搭赠分利规则描述",
                          disabled: "",
                          resize: "none",
                        },
                        model: {
                          value: _vm.obj.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.obj, "content", $$v)
                          },
                          expression: "obj.content",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "包量政策", disabled: "", name: "2" } },
                [
                  _c("div", { staticClass: "policy" }, [
                    _c("div", [_vm._v("政策规则配置")]),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "margin10" },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.obj.ladderRespVo, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "阶梯编码",
                                  prop: "ladderCode",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "阶梯名称",
                                  prop: "ladderName",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "阶梯类型",
                                  prop: "ladderType",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "总销量",
                                  prop: "yearSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度1",
                                  prop: "januarySaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度2",
                                  prop: "februarySaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度3",
                                  prop: "marchSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度4",
                                  prop: "aprilSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度5",
                                  prop: "maySaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度6",
                                  prop: "juneSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度7",
                                  prop: "julySaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度8",
                                  prop: "augustSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度9",
                                  prop: "septemberSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度10",
                                  prop: "octoberSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度11",
                                  prop: "novemberSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度12",
                                  prop: "decemberSaleNum",
                                  align: "center",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "proList margin10" }, [
                    _c("div", { staticClass: "pro_label" }, [_vm._v("产品")]),
                    _c(
                      "div",
                      {
                        staticClass: "cost-select el-textarea el-input--small",
                        staticStyle: { "min-height": "32px" },
                      },
                      _vm._l(_vm.products, function (item, index) {
                        return _c("el-tag", { key: index }, [
                          _vm._v(_vm._s(item.productName || "")),
                        ])
                      }),
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "policy margin10" },
                    [
                      _c(
                        "el-form",
                        { ref: "form", attrs: { "label-width": "100px" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否合并考核" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.obj.combineAssess,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.obj, "combineAssess", $$v)
                                    },
                                    expression: "obj.combineAssess",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "1", name: "1" } },
                                    [_vm._v("是")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "0", name: "0" } },
                                    [_vm._v("否")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.jstFlag
                    ? _c(
                        "div",
                        _vm._l(_vm.jtList, function (item, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  "font-weight": "500",
                                },
                              },
                              [_vm._v(" 阶梯" + _vm._s(index + 1) + " ")]
                            ),
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "jtclass" },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: item.ladderTable,
                                        border: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "阶梯达成比例(%)",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: "",
                                                      placeholder: "",
                                                    },
                                                    model: {
                                                      value: scope.row.ratio,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "ratio",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.ratio",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "奖励类型",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          scope.row.rewardType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "rewardType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.rewardType",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.rewardOptions,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.label,
                                                            value: item.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "奖励名称",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.rewardType == "1"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.rewardName
                                                          )
                                                        ),
                                                      ])
                                                    : scope.row.giftProductVos
                                                    ? _c(
                                                        "span",
                                                        _vm._l(
                                                          scope.row
                                                            .giftProductVos,
                                                          function (
                                                            pitem,
                                                            pindex
                                                          ) {
                                                            return _c(
                                                              "el-tag",
                                                              {
                                                                key: pindex,
                                                                attrs: {
                                                                  disabled: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    pitem.productName ||
                                                                      ""
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _c("span"),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "奖励周期(可多选)",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        multiple: "",
                                                        disabled: "",
                                                        placeholder: "",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .rewardPeriod,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "rewardPeriod",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.rewardPeriod",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.rewardCycle,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.label,
                                                          attrs: {
                                                            label: item.label,
                                                            value: item.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "margin10" },
                              [
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: item.quantityProlist,
                                      border: "",
                                    },
                                  },
                                  [
                                    _vm.obj.combineAssess == 0
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "产品名称",
                                            prop: "productName",
                                            align: "center",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.obj.combineAssess == 0
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "产品编码",
                                            prop: "productCode",
                                            align: "center",
                                          },
                                        })
                                      : _vm._e(),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "季现金奖励(元/箱)",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      scope.row.quarterMoney,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "quarterMoney",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.quarterMoney",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "半年现金奖励(元/箱)",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: "",
                                                    placeholder: "",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.halfYearMoney,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "halfYearMoney",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.halfYearMoney",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "年现金奖励(元/箱)",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: "",
                                                    placeholder: "",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.allYearMoney,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "allYearMoney",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.allYearMoney",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "陈列政策", disabled: "", name: "3" } },
                [
                  _c("div", { staticClass: "policy" }, [
                    _c("div", [_vm._v("政策规则配置")]),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "margin10" },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.obj.ladderRespVo, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "阶梯编码",
                                  prop: "ladderCode",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "阶梯名称",
                                  prop: "ladderName",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "货架陈列面数",
                                  prop: "displayNumber",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "堆头陈列面数",
                                  prop: "pileNumber",
                                  align: "center",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "proList margin10" }, [
                    _c("div", { staticClass: "pro_label" }, [_vm._v("产品")]),
                    _c(
                      "div",
                      {
                        staticClass: "cost-select el-textarea el-input--small",
                        staticStyle: { "min-height": "32px" },
                      },
                      _vm._l(_vm.products, function (item, index) {
                        return _c("el-tag", { key: index }, [
                          _vm._v(_vm._s(item.productName || "")),
                        ])
                      }),
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "dispaly_rules margin10" }, [
                    _c(
                      "div",
                      { staticClass: "stand margin10" },
                      [
                        _c(
                          "el-form",
                          { ref: "form", attrs: { "label-width": "100px" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "上传规则配置" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.obj.uploadConfig,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.obj, "uploadConfig", $$v)
                                      },
                                      expression: "obj.uploadConfig",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("自定时间"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("固定时间上传"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "customized_time" }, [
                      _c(
                        "div",
                        { staticClass: "cycle" },
                        [
                          _c("span", [_vm._v("每")]),
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.obj.dateMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.obj, "dateMode", $$v)
                                },
                                expression: "obj.dateMode",
                              },
                            },
                            _vm._l(_vm.selectcycle, function (item) {
                              return _c("el-option", {
                                key: item.label,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _c("span", [_vm._v("上传次数")]),
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "text",
                              placeholder: "请输入上传次数",
                              disabled: "",
                            },
                            model: {
                              value: _vm.obj.limitNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.obj, "limitNum", $$v)
                              },
                              expression: "obj.limitNum",
                            },
                          }),
                          _c("span", [_vm._v(" 单次上传张数")]),
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "text",
                              placeholder: "请输入上传张数",
                              disabled: "",
                            },
                            model: {
                              value: _vm.obj.sheetNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.obj, "sheetNum", $$v)
                              },
                              expression: "obj.sheetNum",
                            },
                          }),
                          _c("span", [_vm._v(" 通过率")]),
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "text",
                              placeholder: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.obj.passRate,
                              callback: function ($$v) {
                                _vm.$set(_vm.obj, "passRate", $$v)
                              },
                              expression: "obj.passRate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.obj.uploadConfig === "1"
                      ? _c("div", [
                          _c("div", { staticClass: "customized_time" }, [
                            _c(
                              "div",
                              { staticClass: "cycle_content margin10" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "cycle_row" },
                                  _vm._l(
                                    _vm.obj.uploadRespVos,
                                    function (fre, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "cycle_content_item",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "第" +
                                                _vm._s(index + 1) +
                                                "次上传时间："
                                            ),
                                          ]),
                                          _vm.obj.dateMode === "2"
                                            ? _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value: fre.startNum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        fre,
                                                        "startNum",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "fre.startNum",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "apend",
                                                      attrs: { slot: "append" },
                                                      slot: "append",
                                                    },
                                                    [_vm._v("号")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.obj.dateMode === "1"
                                            ? _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value: fre.startNum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        fre,
                                                        "startNum",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "fre.startNum",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.selectcycleDayOptions,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.label,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                          _c("span", [_vm._v("  至  ")]),
                                          _vm.obj.dateMode === "2"
                                            ? _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value: fre.endNum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        fre,
                                                        "endNum",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "fre.endNum",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "apend",
                                                      attrs: { slot: "append" },
                                                      slot: "append",
                                                    },
                                                    [_vm._v("号")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.obj.dateMode === "1"
                                            ? _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value: fre.endNum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        fre,
                                                        "endNum",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "fre.endNum",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.selectcycleDayOptions,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.label,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.obj.uploadConfig === "2"
                      ? _c("div", [
                          _c("div", { staticClass: "customized_time" }, [
                            _c(
                              "div",
                              { staticClass: "cycle_content margin10" },
                              [
                                _c("div", { staticClass: "cycle_row" }, [
                                  _vm.obj.dateMode === "1"
                                    ? _c(
                                        "div",
                                        { staticClass: "cycle_content_item" },
                                        [
                                          _c("span", [
                                            _vm._v("每周上传时间："),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "days_list" },
                                            _vm._l(
                                              _vm.selectcycleDayOptions,
                                              function (ditem, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    class: {
                                                      days_item: true,
                                                      active: ditem.flag,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(ditem.label) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.obj.dateMode === "2"
                                    ? _c(
                                        "div",
                                        { staticClass: "cycle_content_item" },
                                        [
                                          _c("span", [
                                            _vm._v("每月上传时间："),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "days_list" },
                                            _vm._l(
                                              _vm.days,
                                              function (ditem, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    class: {
                                                      days_item: true,
                                                      active: ditem.flag,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(ditem.val) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "upPic" }, [
                      _c(
                        "div",
                        { staticClass: "weekly" },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "18px" } },
                            [_vm._v("货架示例图片：")]
                          ),
                          _vm._l(_vm.shelfSampleUrlArry, function (img, index) {
                            return _c("el-image", {
                              key: index,
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: img,
                                "preview-src-list": _vm.shelfSampleUrlArry,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "weekly" },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "18px" } },
                            [_vm._v("堆头示例图片：")]
                          ),
                          _vm._l(_vm.pileSampleUrlArry, function (img, index) {
                            return _c("el-image", {
                              key: index,
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: img,
                                "preview-src-list": _vm.pileSampleUrlArry,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "policy margin10" }, [
                      _c("div", [_vm._v("奖励规则配置")]),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            attrs: { data: _vm.obj.detailRespVos, border: "" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "阶梯达成比例(%)",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: scope.row.ratio,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "ratio", $$v)
                                          },
                                          expression: "scope.row.ratio",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "奖励类型", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: scope.row.rewardType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "rewardType",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.rewardType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.rewardOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "奖励名称", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.rewardType == "1"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.rewardName)
                                            ),
                                          ])
                                        : scope.row.rewardType == "2"
                                        ? _c(
                                            "span",
                                            _vm._l(
                                              scope.row.giftProductVos,
                                              function (pitem, pindex) {
                                                return _c(
                                                  "el-tag",
                                                  { key: pindex },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        pitem.productName || ""
                                                      )
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "奖励周期(可多选)",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            multiple: "",
                                            disabled: "",
                                            placeholder: "请选择奖励周期",
                                          },
                                          model: {
                                            value: scope.row.rewardPeriods,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "rewardPeriods",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.rewardPeriods",
                                          },
                                        },
                                        _vm._l(
                                          _vm.rewardCycle,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.label,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "季度现金奖励(元/箱)",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: scope.row.quarterMoney,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "quarterMoney",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.quarterMoney",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "半年现金奖励(元/箱)",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: scope.row.halfYearMoney,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "halfYearMoney",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.halfYearMoney",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "年现金奖励(元/箱)",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: scope.row.allYearMoney,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "allYearMoney",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.allYearMoney",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleType == "2" || _vm.ruleType == "3"
            ? _c("div", { staticClass: "proList margin10" }, [
                _c(
                  "div",
                  { staticClass: "pro_label", staticStyle: { width: "auto" } },
                  [
                    _vm._v(" 协议内容 "),
                    _c(
                      "span",
                      {
                        staticClass: "prevArg",
                        on: {
                          click: function ($event) {
                            return _vm.prevArgeement("bl")
                          },
                        },
                      },
                      [_vm._v("预览协议内容")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "协议预览",
            size: "70%",
            height: _vm.dialogHeight,
            visible: _vm.dialogTableVisible,
            modal: true,
            "lock-scroll": true,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("pdf", {
            attrs: { src: _vm.pdfurl, page: _vm.curPage },
            on: { "num-pages": _vm.getPageNum },
          }),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button-group",
                { staticClass: "page" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-arrow-left",
                        disabled: _vm.curPage === 1,
                      },
                      on: { click: _vm.prevPage },
                    },
                    [_vm._v("上一页")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-arrow-right",
                        disabled:
                          !_vm.totalPage || _vm.curPage === _vm.totalPage,
                      },
                      on: { click: _vm.nextPage },
                    },
                    [_vm._v("下一页")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }