<template>
	<div class="custom-select">
		<el-row  v-show="aTpey!=='0'">
			<el-col :offset="1">
				<vxe-toolbar  v-show="aTpey!=='0'">
					<template v-slot:buttons>
						<vxe-button
							class="btnStle"
							type="primary"
							size="mini"
							@click="openModal('org')"
							v-show="aTpey === '1'"
							>新增组织</vxe-button
						>
						<vxe-button
							class="btnStle"
							type="primary"
							size="mini"
							@click="openModal('area')"
							v-show="aTpey === '2'"
							>新增区域</vxe-button
						>
						<vxe-button
							class="btnStle"
							type="primary"
							size="mini"
							@click="openModal('terminal')"
							v-show="aTpey === '3'"
							>新增终端</vxe-button
						>
						<vxe-button
							class="btnStle"
							type="primary"
							size="mini"
							@click="openModal('coumser')"
							v-show="aTpey === '4'"
							>新增经销商</vxe-button
						>
						<vxe-button
							class="btnStle"
							type="primary"
							size="mini"
							@click="clearTermainal"
							v-show="aTpey === '1' || aTpey === '3' || aTpey === '4' || aTpey === '2'"
							>清空</vxe-button
						>
						<vxe-button
							class="btnStle"
							type="primary"
							size="mini"
							v-show="aTpey === '1'"
							>导入
							<input
                type="file"
                id="file1"
                ref="files"
                style="opacity:0;width:100%;height:100%;position:absolute;top:0;left:0;"
                @change="upload"
              />
						</vxe-button>
            <vxe-button
							class="btnStle"
							type="primary"
							size="mini"
							v-show="aTpey === '3'"
							>导入
							<input
                type="file"
                id="file3"
                ref="files"
                style="opacity:0;width:100%;height:100%;position:absolute;top:0;left:0;"
                @change="upload"
              />
						</vxe-button>
            <vxe-button
							class="btnStle"
							type="primary"
							size="mini"
							v-show="aTpey === '4'"
							>导入
							<input
                type="file"
                id="file4"
                ref="files"
                style="opacity:0;width:100%;height:100%;position:absolute;top:0;left:0;"
                @change="upload"
              />
						</vxe-button>
						<vxe-button class="btnStle" type="primary" size="mini" @click="download_modal()" v-if="aTpey==='1'">下载组织模板</vxe-button>
            <vxe-button class="btnStle" type="primary" size="mini" @click="download_modal()" v-if="aTpey==='3'">下载终端模板</vxe-button>
            <vxe-button class="btnStle" type="primary" size="mini" @click="download_modal()" v-if="aTpey==='4'">下载经销商模板</vxe-button>
					</template>
				</vxe-toolbar>
			</el-col>
		</el-row>
		<el-row class="mar10" v-show="aTpey === '1'">
			<el-col :offset="1">
				<vxe-table :data="orgList" border style="width: 100%" ref="orgTable">
					<vxe-table-column
						label="组织编码"
						prop="code"
						align="center"
					></vxe-table-column>
					<vxe-table-column
						label="组织名称"
						prop="name"
						align="center"
					></vxe-table-column>
					<vxe-table-column label="操作" align="center">
						<template #default="{ row,rowIndex }">
							<el-button
								size="mini"
								type="danger"
								@click="deleteTer(row,rowIndex)"
								>删除</el-button
							>
						</template>
					</vxe-table-column>
				</vxe-table>
			</el-col>
		</el-row>
		<el-row class="mar10" v-show="aTpey === '2'">
			<el-col :offset="1">
				<vxe-table :data="areaList.slice((tablePage.currentPage-1)*tablePage.pageSize,tablePage.currentPage*tablePage.pageSize)" border style="width: 100%">
					<vxe-table-column
						label="行政区域编码"
						prop="cityCode"
						align="center"
					></vxe-table-column>
          <vxe-table-column
						label="省"
						prop="provinceName"
						align="center"
					></vxe-table-column>
          <vxe-table-column
						label="市"
						prop="cityName"
						align="center"
					></vxe-table-column>
					<vxe-table-column label="操作" align="center">
						<template #default="{ row,rowIndex }">
							<el-button
								size="mini"
								type="danger"
								@click="deleteTer(row,rowIndex)"
								>删除</el-button
							>
						</template>
					</vxe-table-column>
				</vxe-table>
        <div class="page-pageination" style="margin-top: 20px;">
         <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tablePage.currentPage"
          :page-size="tablePage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="areaList.length"
          style="text-align:right"
        >
        </el-pagination>
    </div>
			</el-col>
		</el-row>
		<el-row class="mar10" v-show="aTpey === '3'">
			<el-col :offset="1">
				<vxe-table :data="terminalList" border style="width: 100%" ref="terTable">
					<vxe-table-column
						label="终端编码"
						prop="code"
						align="center"
					></vxe-table-column>
					<vxe-table-column
						label="终端名称"
						prop="name"
						align="center"
					></vxe-table-column>
					<vxe-table-column
						label="终端等级"
						prop="terminalTypeName"
						align="center"
					></vxe-table-column>
					<vxe-table-column label="操作" align="center">
						<template #default="{ row,rowIndex }">
							<el-button
								size="mini"
								type="danger"
								@click="deleteTer(row,rowIndex)"
								>删除</el-button
							>
						</template>
					</vxe-table-column>
				</vxe-table>
			</el-col>
		</el-row>
		<el-row class="mar10" v-show="aTpey === '4'">
			<el-col :offset="1">
				<vxe-table :data="coumserList" border style="width: 100%" ref="dmsTable">
					<vxe-table-column
						label="经销商编码"
						prop="code"
						align="center"
					></vxe-table-column>
					<vxe-table-column
						label="经销商名称"
						prop="name"
						align="center"
					></vxe-table-column>
					<vxe-table-column label="操作" align="center">
						<template #default="{ row,rowIndex }">
							<el-button
								size="mini"
								type="danger"
								@click="deleteTer(row,rowIndex)"
								>删除</el-button
							>
						</template>
					</vxe-table-column>
				</vxe-table>
			</el-col>
		</el-row>

		<!-- <div class="cost-select el-textarea el-input--small" style="min-height:32px;" @click="openModal">
      <el-tag
          v-for="(item, index) in value"
          :key="index"
          closable
          @close="handleClose(item)"
          >{{ item.productName || '请选择产品' }}</el-tag
        >
    </div> -->
		<!-- 弹框 -->
		<SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
		<!-- <ExportModal :propsObj="propsObjInData"></ExportModal> -->
	</div>
</template>

<script>
// import request from '../../../../utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';
import request from '@/found/utils/request';
import * as utils from '@/found/utils/index';
import ExportModal from '@/found/components/export_modal';
import { log, re, string } from 'mathjs';

export default {
  name: 'terminal',
  props: {
    title: String,
    value: Array,
    disabled: Boolean,
    getValue: Function,
    saveRef: Function,
    objType: {
      type: String,
      default: () => '0',
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      type: '',
      terminalList: [], // 终端列表
      orgList: [], // 组织列表
      coumserList: [], // 客户列表
      areaList: [], // 区域列表
      aTpey: '0', // 1组织 2行政区域 3终端 4经销商
      propsObjInData: {},
      treeList: [],
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
    };
  },
  watch: {
    objType: {
      deep: true,
      handler(val) {
        console.log(val);
        this.aTpey = val;
        if (val === '1') {
          this.terminalList = []; // 终端列表
          this.coumserList = []; // 客户列表
          this.areaList = []; // 区域列表
        } else if (val === '2') {
          this.terminalList = []; // 终端列表
          this.orgList = []; // 组织列表
          this.coumserList = []; // 客户列表
        } else if (val === '3') {
          this.orgList = []; // 组织列表
          this.coumserList = []; // 客户列表
          this.areaList = []; // 区域列表
        } else if (val === '4') {
          this.terminalList = []; // 终端列表
          this.orgList = []; // 组织列表
          this.areaList = []; // 区域列表
        }
      },
    },
  },
  components: {
    SelectConfig,
  },
  async created() {
    // await this.getSaleAreaTreeList();
  },
  mounted() {
    if (this.saveRef) {
      this.saveRef(this);
    }
  },
  methods: {
    formComplete() {
      const formData = this.getFormData();
      // this.getSaleAreaTreeList();
      console.log(formData);
    },
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
    },
    handleCurrentChange(val) {
      this.tablePage.currentPage = val;
    },
    getSaleAreaTreeList() {
      this.$http.post('/mdm/mdmRegionController/treeList').then((res) => {
        console.log(res);
        if (res.success) {
          this.treeList = res.result.data[0].childList || [];
        }
      });
    },
    // 新增弹窗
    openModal(type) {
      this.type = type;
      if (type === 'terminal') {
        console.log('123');

        const params = {
          functionCode: 'terminal_list',
          data: this.terminalList,
          idKey: 'terminalCode',
          paramData: {
            enableStatus: '009',
          },
        };
        this.type = 'terminal';
        this.$refs.selectConfig.modalConfig.title = '选择终端';
        this.$refs.selectConfig.openSelectModal(params);
      }
      if (type === 'org') {
        console.log('123');

        const params = {
          functionCode: 'organization_list',
          data: this.orgList,
          idKey: 'orgCode',
          paramData: {
            enableStatus: '009',
          },
        };

        this.$refs.selectConfig.modalConfig.title = '选择组织';
        this.$refs.selectConfig.openSelectModal(params);
      }
      if (type === 'area') {
        console.log('123');

        const params = {
          functionCode: 'administrative_region',
          data: this.areaList,
          idKey: 'cityCode',
          paramData: {
            enableStatus: '009',
          },
        };
        this.type = 'area';
        this.$refs.selectConfig.modalConfig.title = '选择区域';
        this.$refs.selectConfig.openSelectModal(params);
      }
      if (type === 'coumser') {
        const params = {
          functionCode: 'customer_list',
          data: this.coumserList,
          idKey: 'customerCode',
          paramData: {
            enableStatus: '009',
          },
        };
        this.$refs.selectConfig.modalConfig.title = '选择经销商';
        this.$refs.selectConfig.openSelectModal(params);
      }

      // console.log(params);
    },
    // 选择回调
    onGetSelect(val) {
      console.log(val);
      console.log(this.type);

      if (this.type === 'terminal') {
        const terList = [];
        const data = this.getReset(val, this.terminalList, 'terminalCode');
        console.log(data);
        data.forEach((v) => {
          const obj = {
            name: v.terminalName,
            code: v.terminalCode,
            terminalCode: v.terminalCode,
            terminalLevel: v.terminalType,
            terminalTypeName: v.terminalTypeName,
          };
          terList.push(obj);
        });
        this.terminalList = [...this.terminalList, ...terList];
        console.log(terList);
        console.log(this.terminalList);
      }
      if (this.type === 'org') {
        const orgList = [];
        const data = this.getReset(val, this.orgList, 'orgCode');
        console.log(data);
        data.forEach((v) => {
          const obj = {
            name: v.orgName,
            code: v.orgCode,
            orgCode: v.orgCode,
          };
          orgList.push(obj);
        });
        this.orgList = [...this.orgList, ...orgList];
      }

      if (this.type === 'coumser') {
        const couList = [];
        const data = this.getReset(val, this.coumserList, 'customerCode');
        console.log(data);
        data.forEach((v) => {
          const obj = {
            name: v.customerName,
            code: v.customerCode,
          };
          couList.push(obj);
        });
        this.coumserList = [...this.coumserList, ...couList];
      }
      if (this.type === 'area') {
        // const couList = [];
        // couList.forEach((v) => {
        //   const obj = {
        //     cityName: v.cityName,
        //     provinceName: v.provinceName,
        //     code: v.cityCode,
        //   };
        //   couList.push(obj);
        // });
        this.areaList = val;
      }
    },
    // 判断去重
    getReset(list1, list2, key) {
      for (let i = 0; i < list2.length; i += 1) {
        for (let j = 0; j < list1.length; j += 1) {
          if (list1[j][key] === list2[i][key]) {
            list1.splice(j, 1);
            j -= 1;
          }
        }
      }
      return list1;
    },
    // 删除选择的终端
    deleteTer(row, dindex) {
      console.log(row);
      console.log(dindex);
      if (this.objType === '3') {
        this.$refs.terTable.remove(row);
        this.terminalList.forEach((item, index) => {
          if (item.code === row.code) {
            this.terminalList.splice(index, 1);
          }
        });
        this.$emit('change', this.terminalList);
      } else if (this.objType === '1') {
        this.$refs.orgTable.remove(row);
        this.orgList.forEach((item, index) => {
          if (item.code === row.code) {
            this.orgList.splice(index, 1);
          }
        });
        this.$emit('change', this.orgList);
      } else if (this.objType === '2') {
        this.areaList.splice(dindex, 1);
      } else if (this.objType === '4') {
        this.$refs.dmsTable.remove(row);
        this.coumserList.forEach((item, index) => {
          if (item.code === row.code) {
            this.coumserList.splice(index, 1);
          }
        });
      }
    },
    // 清空终端列表
    clearTermainal() {
      if (this.objType === '3') {
        this.$refs.terTable.remove();
        this.terminalList = [];
        this.$emit('clear', []);
        this.$emit('input', []);
      } else if (this.objType === '1') {
        this.$refs.orgTable.remove();
        this.orgList = [];
        this.$emit('clear', []);
        this.$emit('input', []);
      } else if (this.objType === '2') {
        this.areaList = [];
      } else if (this.objType === '4') {
        this.$refs.dmsTable.remove();
        this.coumserList = [];
        this.$emit('clear', []);
        this.$emit('input', []);
      }
    },
    handleImport() {
      console.log(this);
      let configCode = '';
      if (this.objType === '1') {
        configCode = 'cps_agree_org_import';
      } else if (this.objType === '3') {
        configCode = 'cps_agree_terminal_import';
      } else if (this.objType === '4') {
        configCode = 'cps_agree_cust_import';
      }
      const sid = utils.getUuid(32, 16);
    },
    onGetFileList(value) {
      console.log('onGetFileList');
      console.log(value);
    },
    getpcd(val) {
      console.log(val);
    },
    // 下载终端模版
    download_modal(type) {
      console.log(type);
      let configCode = '';
      if (this.objType === '1') {
        configCode = 'cps_agree_org_import';
      } else if (this.objType === '3') {
        configCode = 'cps_agree_terminal_import';
      } else if (this.objType === '4') {
        configCode = 'cps_agree_cust_import';
      }

      const a = document.createElement('a');
      a.href = `/excel/excelImportController/demoFile?configCode=${configCode}`;
      // a.href = `/excel/excelImportController/demoFile?configCode=${this.propsObj.configCode}&token=${Storage.l.get('TokenKey')}`;
      document.body.appendChild(a);
      a.click(); // 下载
      URL.revokeObjectURL(a.href); // 释放URL 对象
      document.body.removeChild(a); // 删除 a 标签
    },
    // 上传文件
    async upload() {
      let url = '';
      let idname = '';
      if (this.objType === '1') {
        url = '/cps/cpsagreetemplate/analysisOrgExcel';
        idname = 'file1';
      } else if (this.objType === '3') {
        url = '/cps/cpsagreetemplate/analysisTerminalExcel';
        idname = 'file3';
      } else if (this.objType === '4') {
        url = '/cps/cpsagreetemplate/analysisCustExcel';
        idname = 'file4';
      }
      const loading = this.$loading({
        lock: true,
        text: '文件上传中...',
        spinner: 'el-icon-loading',
      });
      const fileObj = document.getElementById(idname).files; // js 获取文件对象
      const fileval = document.getElementById(idname);
      const form = new FormData();
      form.append('file', this.file);
      if (fileObj.length !== 0) {
        const i = fileObj.length;
        this.counts = i;
        for (let j = 0; j < i; j += 1) {
          (function (k) {
            form.append('file', fileObj[k]); // 文件对象
          }(j));
        }
      }
      try {
        const result = await request.post(url, form);
        if (result.success) {
          this.$message.success('导入成功');
          loading.close();
          const resData = result.result;
          const orgArr = [];
          const terArr = [];
          const dmsArr = [];
          console.log(resData);
          if (this.objType === '1') {
            for (const item of resData) {
              orgArr.push({
                name: item.name,
                code: item.code,
              });
            }
            const { orgTable } = this.$refs;
            const { rows } = await orgTable.insertAt(orgArr, undefined);
            console.log(rows);
            this.orgList = [...rows, ...this.orgList];
            // this.$emit('input', this.orgList);
          } else if (this.objType === '3') {
            for (const item of resData) {
              terArr.push({
                name: item.name,
                code: item.code,
                terminalTypeName: item.terminalLevel,
              });
            }
            const { terTable } = this.$refs;
            const { rows } = await terTable.insertAt(terArr, undefined);
            this.terminalList = [...rows, ...this.terminalList];
            this.$emit('input', this.terminalList);
          } else if (this.objType === '4') {
            for (const item of resData) {
              dmsArr.push({
                name: item.name,
                code: item.code,
              });
            }
            const { dmsTable } = this.$refs;
            const { rows } = await dmsTable.insertAt(dmsArr, undefined);
            this.coumserList = [...rows, ...this.coumserList];
            this.$emit('input', this.coumserList);
          }
          fileval.value = '';
          // $(`#${idname}`)[0].value = null;
          // for (const item of resData) {
          //   tempArr.push({
          //     userName: item.userName,
          //     userCode: item.userCode,
          //     // taskIndicator: 0,
          //     terminalTaskIndicator: item.terminalTaskIndicator,
          //     // visitNumberTaskIndicator: item.visitNumberTaskIndicator,
          //     userOrgCode: item.orgCode,
          //     userOrgName: item.orgName,
          //   });
          // }
          // const { fineTable } = this.$refs;
          // const { rows } = await fineTable.insertAt(tempArr, undefined);
          // this.subjectList = [...rows, ...this.subjectList];
          // this.$emit('input', this.subjectList);
        }
      } catch (error) {
        loading.close();
      }
    },
    // 清除选择
    clearFn(e) {
      e.stopPropagation();
      this.$emit('change');
    },
  },
};
</script>

<style lang="less" scoped>
	.mar10 {
		margin-top: 10px;
	}
	.cost-select {
		position: relative;
		border: 1px solid #dddfe6;
		border-radius: 5px;
		padding: 5px;

		/deep/ .el-input__inner {
			padding-right: 30px;
		}

		.clear-icon {
			width: 25px;
			height: 100%;
			position: absolute;
			right: 5px;
			top: 0;
			text-align: center;
			color: #c0c4cc;
			transition: all 0.3s;
			padding-top: 1px;
			display: none;

			.el-icon-circle-close {
				width: 100%;
				font-size: 14px;
				cursor: pointer;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
			}
		}

		&:hover {
			.clear-icon {
				display: block;
			}
		}
	}
	.btnStle {
		background-color: #c34231 !important; /* 红色背景 */
		color: #fff !important; /* 白色文字 */
		border: none !important; /* 无边框 */
		&:focus {
			box-shadow: none !important;
		}
	}
</style>
