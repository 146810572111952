var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-select" },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.aTpey !== "0",
              expression: "aTpey!=='0'",
            },
          ],
        },
        [
          _c(
            "el-col",
            { attrs: { offset: 1 } },
            [
              _c("vxe-toolbar", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.aTpey !== "0",
                    expression: "aTpey!=='0'",
                  },
                ],
                scopedSlots: _vm._u([
                  {
                    key: "buttons",
                    fn: function () {
                      return [
                        _c(
                          "vxe-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.aTpey === "1",
                                expression: "aTpey === '1'",
                              },
                            ],
                            staticClass: "btnStle",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.openModal("org")
                              },
                            },
                          },
                          [_vm._v("新增组织")]
                        ),
                        _c(
                          "vxe-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.aTpey === "2",
                                expression: "aTpey === '2'",
                              },
                            ],
                            staticClass: "btnStle",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.openModal("area")
                              },
                            },
                          },
                          [_vm._v("新增区域")]
                        ),
                        _c(
                          "vxe-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.aTpey === "3",
                                expression: "aTpey === '3'",
                              },
                            ],
                            staticClass: "btnStle",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.openModal("terminal")
                              },
                            },
                          },
                          [_vm._v("新增终端")]
                        ),
                        _c(
                          "vxe-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.aTpey === "4",
                                expression: "aTpey === '4'",
                              },
                            ],
                            staticClass: "btnStle",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.openModal("coumser")
                              },
                            },
                          },
                          [_vm._v("新增经销商")]
                        ),
                        _c(
                          "vxe-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.aTpey === "1" ||
                                  _vm.aTpey === "3" ||
                                  _vm.aTpey === "4" ||
                                  _vm.aTpey === "2",
                                expression:
                                  "aTpey === '1' || aTpey === '3' || aTpey === '4' || aTpey === '2'",
                              },
                            ],
                            staticClass: "btnStle",
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.clearTermainal },
                          },
                          [_vm._v("清空")]
                        ),
                        _c(
                          "vxe-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.aTpey === "1",
                                expression: "aTpey === '1'",
                              },
                            ],
                            staticClass: "btnStle",
                            attrs: { type: "primary", size: "mini" },
                          },
                          [
                            _vm._v("导入 "),
                            _c("input", {
                              ref: "files",
                              staticStyle: {
                                opacity: "0",
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: "0",
                                left: "0",
                              },
                              attrs: { type: "file", id: "file1" },
                              on: { change: _vm.upload },
                            }),
                          ]
                        ),
                        _c(
                          "vxe-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.aTpey === "3",
                                expression: "aTpey === '3'",
                              },
                            ],
                            staticClass: "btnStle",
                            attrs: { type: "primary", size: "mini" },
                          },
                          [
                            _vm._v("导入 "),
                            _c("input", {
                              ref: "files",
                              staticStyle: {
                                opacity: "0",
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: "0",
                                left: "0",
                              },
                              attrs: { type: "file", id: "file3" },
                              on: { change: _vm.upload },
                            }),
                          ]
                        ),
                        _c(
                          "vxe-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.aTpey === "4",
                                expression: "aTpey === '4'",
                              },
                            ],
                            staticClass: "btnStle",
                            attrs: { type: "primary", size: "mini" },
                          },
                          [
                            _vm._v("导入 "),
                            _c("input", {
                              ref: "files",
                              staticStyle: {
                                opacity: "0",
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: "0",
                                left: "0",
                              },
                              attrs: { type: "file", id: "file4" },
                              on: { change: _vm.upload },
                            }),
                          ]
                        ),
                        _vm.aTpey === "1"
                          ? _c(
                              "vxe-button",
                              {
                                staticClass: "btnStle",
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.download_modal()
                                  },
                                },
                              },
                              [_vm._v("下载组织模板")]
                            )
                          : _vm._e(),
                        _vm.aTpey === "3"
                          ? _c(
                              "vxe-button",
                              {
                                staticClass: "btnStle",
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.download_modal()
                                  },
                                },
                              },
                              [_vm._v("下载终端模板")]
                            )
                          : _vm._e(),
                        _vm.aTpey === "4"
                          ? _c(
                              "vxe-button",
                              {
                                staticClass: "btnStle",
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.download_modal()
                                  },
                                },
                              },
                              [_vm._v("下载经销商模板")]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.aTpey === "1",
              expression: "aTpey === '1'",
            },
          ],
          staticClass: "mar10",
        },
        [
          _c(
            "el-col",
            { attrs: { offset: 1 } },
            [
              _c(
                "vxe-table",
                {
                  ref: "orgTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.orgList, border: "" },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { label: "组织编码", prop: "code", align: "center" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { label: "组织名称", prop: "name", align: "center" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var rowIndex = ref.rowIndex
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteTer(row, rowIndex)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.aTpey === "2",
              expression: "aTpey === '2'",
            },
          ],
          staticClass: "mar10",
        },
        [
          _c(
            "el-col",
            { attrs: { offset: 1 } },
            [
              _c(
                "vxe-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.areaList.slice(
                      (_vm.tablePage.currentPage - 1) * _vm.tablePage.pageSize,
                      _vm.tablePage.currentPage * _vm.tablePage.pageSize
                    ),
                    border: "",
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: {
                      label: "行政区域编码",
                      prop: "cityCode",
                      align: "center",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      label: "省",
                      prop: "provinceName",
                      align: "center",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: { label: "市", prop: "cityName", align: "center" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var rowIndex = ref.rowIndex
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteTer(row, rowIndex)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "page-pageination",
                  staticStyle: { "margin-top": "20px" },
                },
                [
                  _c("el-pagination", {
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      "current-page": _vm.tablePage.currentPage,
                      "page-size": _vm.tablePage.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.areaList.length,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.aTpey === "3",
              expression: "aTpey === '3'",
            },
          ],
          staticClass: "mar10",
        },
        [
          _c(
            "el-col",
            { attrs: { offset: 1 } },
            [
              _c(
                "vxe-table",
                {
                  ref: "terTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.terminalList, border: "" },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { label: "终端编码", prop: "code", align: "center" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { label: "终端名称", prop: "name", align: "center" },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      label: "终端等级",
                      prop: "terminalTypeName",
                      align: "center",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var rowIndex = ref.rowIndex
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteTer(row, rowIndex)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.aTpey === "4",
              expression: "aTpey === '4'",
            },
          ],
          staticClass: "mar10",
        },
        [
          _c(
            "el-col",
            { attrs: { offset: 1 } },
            [
              _c(
                "vxe-table",
                {
                  ref: "dmsTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.coumserList, border: "" },
                },
                [
                  _c("vxe-table-column", {
                    attrs: {
                      label: "经销商编码",
                      prop: "code",
                      align: "center",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      label: "经销商名称",
                      prop: "name",
                      align: "center",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var rowIndex = ref.rowIndex
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteTer(row, rowIndex)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }