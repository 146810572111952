import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import detailsInfo from './details.vue';

formCreate.component('detailsInfo', detailsInfo);
export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'detailsForm',
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  created() {

  },

  methods: {
    formComplete() {
      this.getDetails();
      console.log(this.formConfig);
    },
    setRule(item) {
      const v = item;
      console.log(v);
      if (item.field === 'agreeDateRange') {
        item.props = {
          ...item.props,
          type: 'daterange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd',
        };
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              agreeDateRange: e,
            });
          },
        };
      }
      if (item.field === 'signDateRange') {
        item.props = {
          ...item.props,
          type: 'daterange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd',
        };
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              signDateRange: e,
            });
          },
        };
      }
      return v;
    },
    async getDetails() {
      await request.post('/cps/cpsagreetemplate/query', { code: this.formConfig.agreeCode }).then((res) => {
        if (res.success) {
          console.log([res.result.agreeStartTime, res.result.agreeEndTime]);
          console.log(res.result.signLevel.split(','));

          let resuletObj = {};
          const pileSampleUrlArry = [];
          const shelfSampleUrlArry = [];
          if (res.result.type === '1') {
            resuletObj = res.result.followRespVo;
          } else if (res.result.type === '2') {
            resuletObj = res.result.quantifyRespVo;
          } else if (res.result.type === '3') {
            resuletObj = res.result.displayRespVo;
            res.result.displayRespVo.shelfSampleUrl.forEach((element) => {
              shelfSampleUrlArry.push(element.url);
            });
            res.result.displayRespVo.pileSampleUrl.forEach((element) => {
              pileSampleUrlArry.push(element.url);
            });
          }
          console.log(res.result.signLevel.split(','));
          console.log(res.result.signType.split(','));
          this.setValue({
            ...res.result,
            agreeDateRange: [res.result.agreeStartTime, res.result.agreeEndTime],
            signDateRange: [res.result.signStartTime, res.result.signEndTime],
            signLevel: res.result.signLevel.split(','),
            signType: res.result.signType.split(','),
          });
          const detailsInfoData = this.getRule('detailsInfo');
          this.fApi.el('detailsInfo').ruleType = res.result.type;
          this.fApi.el('detailsInfo').pdfurl = `/upload/downloadController/download?objectName=${res.result.agreeTemplateUrl}`;
          detailsInfoData.props = {
            ...detailsInfoData.props,
            obj: resuletObj,
            object: res.result.object,
            objects: res.result.objects,
            // ruleType: res.result.type,
            products: res.result.products,
            shelfSampleUrlArry,
            pileSampleUrlArry,
          };
        }
      });
    },
  },
};
