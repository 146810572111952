<template>
	<div>
		<div class="titlerule">协议包含政策类型(单选)</div>
		<div class="tabs">
			<el-tabs v-model="activeIndex" @tab-click="tabClick">
				<el-tab-pane label="随单搭赠" name="1">
					<el-button type="primary" @click="openModal('product')" size="mini"
						>添加产品</el-button
					>
					<div class="proList margin10">
						<div class="pro_label">产品</div>
						<div
							class="cost-select el-textarea el-input--small"
							style="min-height: 32px"
						>
							<el-tag
								v-for="(item, index) in prolist"
								:key="index"
								closable
								@close="handleClose(item, 'product')"
								>{{ item.productName || "" }}</el-tag
							>
						</div>
					</div>
					<div class="stand margin10">
						<el-form ref="form" label-width="100px">
							<el-form-item label="搭赠计算标准">
								<el-radio-group v-model="standard">
									<el-radio label="1">单阶梯赠</el-radio>
									<el-radio label="2">多阶梯赠</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-form>
					</div>
					<div class="stand_list">
						<div class="stand_list_item">
							<el-row>
								<el-col
									:span="24"
									class="stand_item"
									v-for="(item, index) in standList"
									:key="item.id"
								>
									<div>入库满</div>
									<div>
										<el-input
											v-model="item.num"
											type="number"
											size="mini"
											:inline="true"
										/>
									</div>
									<div>箱,奖励</div>
									<div>
										<el-select
											v-model="item.reward"
											placeholder="请选择"
											:inline="true"
											size="mini"
										>
											<el-option
												v-for="item in rewardOptions"
												:key="item.value"
												:label="item.label"
												:value="item.value"
											>
											</el-option>
										</el-select>
									</div>
									<div
										class="cost-select el-textarea el-input--small"
										style="
											min-height: 32px;
											width: 400px;
											word-wrap: break-word;
										"
										@click="openModal('standproduct', index)"
										v-if="item.reward == 2"
									>
										<div
											v-if="item.standproduct.length > 0"
											style="flex-wrap: wrap"
										>
											<el-tag
												v-for="(pitem, pindex) in item.standproduct"
												:key="pindex"
												closable
												@close="handleStandClose(pitem, index)"
												>{{ pitem.productName || "" }}</el-tag
											>
										</div>
										<div v-else style="height: 10px; width: 200px">
											请选择商品
										</div>
									</div>
									<div v-if="item.reward == 1">
										<el-input
											type="number"
											v-model="item.rewardNum"
											:inline="true"
											size="mini"
										/>元
									</div>
									<div v-if="item.reward == 2">
										各&nbsp;<el-input
											type="number"
											:inline="true"
											size="mini"
											v-model="item.rewardNum"
										/>瓶
									</div>
									<div class="stand_icon">
										<i
											v-if="
												standard == '2' && standList.length > 1 && index > 0
											"
											class="el-icon-remove-outline"
											style="font-size: 24px; color: #c34231"
											@click="removeRow(index)"
										></i>
										<i
											v-if="standard == '2' && index == 0"
											class="el-icon-circle-plus-outline"
											style="font-size: 24px; color: #c34231"
											@click="addRow"
										></i>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
					<div class="margin10">
						<div>搭赠分利规则描述</div>
						<el-input
							type="textarea"
							:rows="10"
							placeholder="请输入搭赠分利规则描述"
							v-model="textarea"
							resize="none"
						>
						</el-input>
					</div>
				</el-tab-pane>
				<el-tab-pane label="包量政策" name="2">
					<div class="policy">
						<div>政策规则配置</div>
						<div class="last" @click="openModal('policy')">
							<i
								class="el-icon-circle-plus-outline"
								style="font-size: 24px; color: #c34231"
							></i
							><span>添加阶梯</span>
						</div>
					</div>
					<el-row class="margin10">
						<el-col>
							<el-table :data="policyList" border style="width: 100%">
								<el-table-column
									label="阶梯编码"
									prop="ladderCode"
									align="center"
								></el-table-column>
								<el-table-column
									label="阶梯名称"
									prop="ladderName"
									align="center"
								></el-table-column>
								<el-table-column
									label="阶梯类型"
									prop="ladderType"
									align="center"
								></el-table-column>
								<el-table-column
									label="总销量"
									prop="yearSaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度1"
									prop="januarySaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度2"
									prop="februarySaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度3"
									prop="marchSaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度4"
									prop="aprilSaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度5"
									prop="maySaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度6"
									prop="juneSaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度7"
									prop="julySaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度8"
									prop="augustSaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度9"
									prop="septemberSaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度10"
									prop="octoberSaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度11"
									prop="novemberSaleNum"
									align="center"
								></el-table-column>
								<el-table-column
									label="月度12"
									prop="decemberSaleNum"
									align="center"
								></el-table-column>
							</el-table>
						</el-col>
					</el-row>
					<el-button
						class="margin10"
						type="primary"
						@click="openModal('quantity')"
						size="mini"
						>添加产品</el-button
					>
					<div class="proList margin10">
						<div class="pro_label">产品</div>
						<div
							class="cost-select el-textarea el-input--small"
							style="min-height: 32px"
						>
							<el-tag
								v-for="(item, index) in quantityProlist"
								:key="index"
								closable
								@close="handleClose(item, 'quantity')"
								>{{ item.productName || "" }}</el-tag
							>
						</div>
					</div>
					<div class="policy margin10">
						<el-form ref="form" label-width="100px">
							<el-form-item label="是否合并考核">
								<el-radio-group v-model="check">
									<el-radio label="1" name="1">是</el-radio>
									<el-radio label="0" name="0">否</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-form>
						<div class="last" @click="addJtRow()">
							<i
								class="el-icon-circle-plus-outline"
								style="font-size: 24px; color: #c34231"
							></i
							><span>添加阶梯</span>
						</div>
					</div>
					<div v-for="(item, index) in jtList" :key="index">
						<div style="font-size: 14px; font-weight: 500">
							阶梯{{ index + 1 }}
						</div>
						<div>
							<div class="jtclass">
								<!-- <el-form :model="formData" :rules="formData.rules" ref="formRef"> -->
								<el-table :data="item.ladderTable" border>
									<el-table-column label="阶梯达成比例(%)" align="center">
										<template slot-scope="scope">
											<!-- <el-form-item :prop="'ladderTable.' + scope.$index + '.num'" :rules="formData.rules.num"> -->
											<el-input
												v-model="scope.row.ratio"
												clearable
												placeholder="请输入阶梯达成比例"
											></el-input>
											<!-- </el-form-item> -->
										</template>
									</el-table-column>
									<el-table-column label="奖励类型" align="center">
										<template slot-scope="scope">
											<el-select
												v-model="scope.row.rewardType"
												clearable
												placeholder="请选择"
												@change="getSelectVal(index, scope.row)"
											>
												<el-option
													v-for="item in rewardTypeList"
													:key="item.value"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
											<div
												style="
													font-size: 14px;
													color: #c34231;
													margin-top: 10px;
													cursor: pointer;
												"
												@click="openModal('blProReward', index)"
												v-if="scope.row.rewardType == '2'"
											>
												添加实物奖励
											</div>
										</template>
									</el-table-column>
									<el-table-column label="奖励名称" align="center">
										<template slot-scope="scope">
											<span v-if="scope.row.rewardType == '1'">现金奖励</span>
											<span v-else-if="scope.row.giftProductVos">
												<el-tag
													v-for="(pitem, pindex) in scope.row.giftProductVos"
													:key="pindex"
													closable
													@close="
														handleClose(pitem, 'blProReward', scope.$index)
													"
													>{{ pitem.productName || "" }}</el-tag
												>
											</span>
											<span v-else></span>
										</template>
									</el-table-column>
									<el-table-column label="奖励周期(可多选)" align="center">
										<template slot-scope="scope">
											<el-select
												v-model="scope.row.rewardPeriod"
												multiple
												clearable
												placeholder="请选择奖励周期"
												@change="judgeCol(scope.row, index)"
											>
												<el-option
													v-for="item in rewardCycle"
													:key="item.label"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
										</template>
									</el-table-column>
								</el-table>
								<el-button size="mini" type="danger" @click="removeJtRow(index)"
									>删除</el-button
								>
							</div>
							<!-- </el-form> -->
						</div>
						<div class="margin10">
							<el-table :data="item.quantityProlist" border v-if="check == 0">
								<el-table-column
									label="产品名称"
									prop="productName"
									align="center"
								></el-table-column>
								<el-table-column
									label="产品编码"
									prop="productCode"
									align="center"
								></el-table-column>
								<el-table-column label="季现金奖励(元/箱)" align="center">
									<template slot-scope="scope">
										<el-input
											v-model="scope.row.quarterMoney"
											clearable
											placeholder="请输入季现金奖励"
											v-if="item.quarterFlag"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="半年现金奖励(元/箱)" align="center">
									<template slot-scope="scope">
										<el-input
											v-model="scope.row.halfYearMoney"
											clearable
											placeholder="请输入半年现金奖励"
											v-if="item.halfFlag"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="年现金奖励(元/箱)" align="center">
									<template slot-scope="scope">
										<el-input
											v-model="scope.row.allYearMoney"
											clearable
											placeholder="请输入年现金奖励"
											v-if="item.allyearFlag"
										></el-input>
									</template>
								</el-table-column>
							</el-table>
							<el-table :data="item.hbList" border v-if="check == 1">
								<!-- <el-table-column label="产品名称" prop="productName" align="center">
                        <template>
                              <span v-for="(citem,index) in item.hbList[0].proList" :key="index" >{{citem. productName }}/</span>
                        </template>
                     </el-table-column>
                     <el-table-column label="产品编码" prop="productCode" align="center">
                      <template>
                           <span v-for="(citem,index)  in item.hbList[0].proList" :key="index" >{{citem. productCode }}/</span>
                       </template>

                     </el-table-column> -->
								<el-table-column label="季现金奖励(元/箱)" align="center">
									<template slot-scope="scope">
										<el-input
											v-model="scope.row.quarterMoney"
											clearable
											placeholder="请输入季现金奖励"
											v-if="item.quarterFlag"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="半年现金奖励(元/箱)" align="center">
									<template slot-scope="scope">
										<el-input
											v-model="scope.row.halfYearMoney"
											clearable
											placeholder="请输入半年现金奖励"
											v-if="item.halfFlag"
										></el-input>
									</template>
								</el-table-column>
								<el-table-column label="年现金奖励(元/箱)" align="center">
									<template slot-scope="scope">
										<el-input
											v-model="scope.row.allYearMoney"
											clearable
											placeholder="请输入年现金奖励"
											v-if="item.allyearFlag"
										></el-input>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="陈列政策" name="3">
					<div class="policy">
						<div>政策规则配置</div>
						<div class="last" @click="openModal('display')">
							<i
								class="el-icon-circle-plus-outline"
								style="font-size: 24px; color: #c34231"
							></i
							><span>添加阶梯</span>
						</div>
					</div>
					<el-row class="margin10">
						<el-col>
							<el-table :data="displyJtList" border style="width: 100%">
								<el-table-column
									label="阶梯编码"
									prop="ladderCode"
									align="center"
								></el-table-column>
								<el-table-column
									label="阶梯名称"
									prop="ladderName"
									align="center"
								></el-table-column>
								<el-table-column
									label="货架陈列面数"
									prop="displayNumber"
									align="center"
								></el-table-column>
								<el-table-column
									label="堆头陈列面数"
									prop="pileNumber"
									align="center"
								></el-table-column>
							</el-table>
						</el-col>
					</el-row>
					<el-button
						class="margin10"
						type="primary"
						@click="openModal('dispalypro')"
						size="mini"
						>添加产品</el-button
					>
					<div class="proList margin10">
						<div class="pro_label">产品</div>
						<div
							class="cost-select el-textarea el-input--small"
							style="min-height: 32px"
						>
							<el-tag
								v-for="(item, index) in displayProlist"
								:key="index"
								closable
								@close="handleClose(item, 'dispalypro')"
								>{{ item.productName || "" }}</el-tag
							>
						</div>
					</div>
					<div class="dispaly_rules margin10">
						<div class="stand margin10">
							<el-form ref="form" label-width="100px">
								<el-form-item label="上传规则配置">
									<el-radio-group v-model="timeRadio">
										<el-radio label="1">自定时间</el-radio>
										<el-radio label="2">固定时间上传</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-form>
						</div>
						<div class="customized_time">
							<div class="cycle">
								<span>每</span>
								<el-select v-model="selectcycleVal">
									<el-option
										v-for="item in selectcycle"
										:key="item.label"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
								<span>上传次数</span>
								<el-input
									type="text"
									placeholder="请输入上传次数"
									style="width: 200px"
									v-model="limitNum"
									@blur="bluraddElements($event)"
								></el-input>
								<span> 单次上传张数</span>
								<el-input
									type="text"
									placeholder="请输入上传张数"
									v-model="sheetNum"
									style="width: 200px"
								></el-input>
								<span> 通过率</span>
								<el-input
									type="text"
									placeholder="请输入通过率"
									v-model="passRate"
									style="width: 200px"
								></el-input>
							</div>
						</div>
						<div v-if="timeRadio === '1'">
							<div class="customized_time">
								<div class="cycle_content margin10">
									<div class="cycle_row">
										<div
											class="cycle_content_item"
											v-for="(fre, index) in timeList"
											:key="index"
										>
											<span>第{{ index + 1 }}次上传时间：</span>
											<el-input
												placeholder="请输入内容"
												v-model="fre.start"
												style="width: 200px"
												v-if="selectcycleVal === '2'"
											>
												<span slot="append" class="apend">号</span>
											</el-input>
											<el-select
												v-model="fre.start"
												placeholder=""
												v-if="selectcycleVal === '1'"
											>
												<el-option
													v-for="item in selectcycleDayOptions"
													:key="item.label"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
											<span>&nbsp;&nbsp;至&nbsp;&nbsp;</span>
											<el-input
												placeholder="请输入内容"
												v-model="fre.end"
												style="width: 200px"
												v-if="selectcycleVal === '2'"
											>
												<span slot="append" class="apend">号</span>
											</el-input>
											<el-select
												v-model="fre.end"
												placeholder=""
												v-if="selectcycleVal === '1'"
											>
												<el-option
													v-for="item in selectcycleDayOptions"
													:key="item.label"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="timeRadio === '2'">
							<div class="customized_time">
								<div class="cycle_content margin10">
									<div class="cycle_row">
										<div
											class="cycle_content_item"
											v-if="selectcycleVal === '1'"
										>
											<span>每周上传时间：</span>
											<div class="days_list">
												<div
													@click="choiceDay(index, 'day')"
													:class="{ days_item: true, active: ditem.flag }"
													v-for="(ditem, index) in selectcycleDayOptions"
													:key="index"
												>
													{{ ditem.label }}
												</div>
											</div>
										</div>
										<div
											class="cycle_content_item"
											v-if="selectcycleVal === '2'"
										>
											<span>每月上传时间：</span>
											<div class="days_list">
												<div
													@click="choiceDay(index, 'month')"
													:class="{ days_item: true, active: ditem.flag }"
													v-for="(ditem, index) in days"
													:key="index"
												>
													{{ ditem.val }}
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="cycle_row">
										<div
											class="cycle_content_item"
											v-for="(fre, index) in timeList"
											:key="index"
										>
											<span>上传时间：</span>
											<el-input
												placeholder="请输入内容"
												v-model="fre.start"
												style="width: 200px"
												v-if="selectcycleVal === '2'"
											>
												<span slot="append" class="apend">号</span>
											</el-input>
											<el-select
												v-model="fre.start"
												placeholder=""
												v-if="selectcycleVal === '1'"
											>
												<el-option
													v-for="item in selectcycleDayOptions"
													:key="item.label"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
											<span>&nbsp;&nbsp;至&nbsp;&nbsp;</span>
											<el-input
												placeholder="请输入内容"
												v-model="fre.end"
												style="width: 200px"
												v-if="selectcycleVal === '2'"
											>
												<span slot="append" class="apend">号</span>
											</el-input>
											<el-select
												v-model="fre.end"
												placeholder=""
												v-if="selectcycleVal === '1'"
											>
												<el-option
													v-for="item in selectcycleDayOptions"
													:key="item.label"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
										</div>
									</div> -->
								</div>
							</div>
						</div>
						<div class="upPic">
							<div class="weekly">
								<span style="margin-right: 18px">货架示例图片：</span>
								<el-upload
									:headers="headerObj"
									action="/upload/uploadController/uploadOssList"
									:with-credentials="true"
									list-type="picture-card"
									:auto-upload="true"
									:on-preview="handlePictureCardPreview"
									:on-remove="
										(file, fileList) => handleRemove(file, fileList, 'shelf')
									"
									:on-success="
										(response, file, fileList) =>
											handleSuccess(response, file, fileList, 'shelf')
									"
									:file-list="shelfSampleUrl"
									accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF"
								>
									<i slot="default" class="el-icon-plus"></i>
								</el-upload>
								<el-dialog :visible.sync="dialogVisible" :modal="false">
									<img width="100%" :src="dialogImageUrl" alt="" />
								</el-dialog>
							</div>
							<div class="weekly">
								<span style="margin-right: 18px">堆头示例图片：</span>
								<el-upload
									:headers="headerObj"
									action="/upload/uploadController/uploadOssList"
									:with-credentials="true"
									list-type="picture-card"
									:auto-upload="true"
									:on-preview="handlePictureCardPreview"
									:on-remove="
										(file, fileList) => handleRemove(file, fileList, 'pile')
									"
									:on-success="
										(response, file, fileList) =>
											handleSuccess(response, file, fileList, 'pile')
									"
									:file-list="pileSampleUrl"
									accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF"
								>
									<i slot="default" class="el-icon-plus"></i>
								</el-upload>
								<el-dialog :visible.sync="dialogVisible" :modal="false">
									<img width="100%" :src="dialogImageUrl" alt="" />
								</el-dialog>
							</div>
						</div>
						<div class="policy margin10">
							<div>奖励规则配置</div>
							<div class="last" @click="dispalyAdd()">
								<i
									class="el-icon-circle-plus-outline"
									style="font-size: 24px; color: #c34231"
								></i
								><span>添加阶梯</span>
							</div>
						</div>
						<div>
							<el-table :data="displayladderTable" border>
								<el-table-column label="操作" width="120">
									<template slot-scope="scope">
										<el-button
											size="mini"
											type="danger"
											@click="delDisplayRow(scope.$index)"
											>删除</el-button
										>
									</template>
								</el-table-column>
								<el-table-column label="阶梯达成比例(%)" align="center">
									<template slot-scope="scope">
										<!-- <el-form-item :prop="'ladderTable.' + scope.$index + '.num'" :rules="formData.rules.num"> -->
										<el-input
											v-model="scope.row.ratio"
											clearable
											placeholder="请输入阶梯达成比例"
										></el-input>
										<!-- </el-form-item> -->
									</template>
								</el-table-column>
								<el-table-column label="奖励类型" align="center">
									<template slot-scope="scope">
										<el-select
											v-model="scope.row.rewardType"
											clearable
											placeholder="请选择"
											@change="getDisplaySelectVal(scope.$index, scope.row)"
										>
											<el-option
												v-for="item in rewardTypeList"
												:key="item.value"
												:label="item.label"
												:value="item.value"
											>
											</el-option>
										</el-select>
										<div
											style="
												font-size: 14px;
												color: #c34231;
												margin-top: 10px;
												cursor: pointer;
											"
											@click="openModal('displayProReward', scope.$index)"
											v-if="scope.row.rewardType == '2'"
										>
											添加实物奖励
										</div>
									</template>
								</el-table-column>
								<el-table-column label="奖励名称" align="center">
									<template slot-scope="scope">
										<span v-if="scope.row.rewardType == '1'">现金奖励</span>
										<span v-else-if="scope.row.rewardType == '2'">
											<el-tag
												v-for="(pitem, pindex) in scope.row.giftProductVos"
												:key="pindex"
												closable
												@close="
													handleClose(pitem, 'displayProReward', scope.$index)
												"
												>{{ pitem.productName || "" }}</el-tag
											>
										</span>
										<span v-else></span>
									</template>
								</el-table-column>
								<el-table-column label="奖励周期(可多选)" align="center">
									<template slot-scope="scope">
										<el-select
											v-model="scope.row.rewardPeriod"
											multiple
											clearable
											placeholder="请选择奖励周期"
											@change="judgeDislpayCol(scope.row, scope.$index)"
										>
											<el-option
												v-for="item in rewardCycle"
												:key="item.label"
												:label="item.label"
												:value="item.value"
											>
											</el-option>
										</el-select>
									</template>
								</el-table-column>
								<el-table-column label="季度达成奖励数量" align="center">
									<template slot-scope="scope">
										<!-- <el-form-item :prop="'ladderTable.' + scope.$index + '.num'" :rules="formData.rules.num"> -->
										<el-input
											v-model="scope.row.quarterMoney"
											clearable
											placeholder="季度达成奖励数量"
											v-if="displayladderTable[scope.$index].quarterFlag"
										></el-input>
										<!-- </el-form-item> -->
									</template>
								</el-table-column>
								<el-table-column label="半年达成奖励数量" align="center">
									<template slot-scope="scope">
										<!-- <el-form-item :prop="'ladderTable.' + scope.$index + '.num'" :rules="formData.rules.num"> -->
										<el-input
											v-model="scope.row.halfYearMoney"
											clearable
											placeholder="半年达成奖励数量"
											v-if="displayladderTable[scope.$index].halfFlag"
										></el-input>
										<!-- </el-form-item> -->
									</template>
								</el-table-column>
								<el-table-column label="年度达成奖励数量" align="center">
									<template slot-scope="scope">
										<!-- <el-form-item :prop="'ladderTable.' + scope.$index + '.num'" :rules="formData.rules.num"> -->
										<el-input
											v-model="scope.row.allYearMoney"
											clearable
											placeholder="年度达成奖励数量"
											v-if="displayladderTable[scope.$index].allyearFlag"
										></el-input>
										<!-- </el-form-item> -->
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
			<div
				class="proList margin10"
				v-if="activeIndex == '2' || activeIndex == '3'"
			>
				<div class="pro_label" style="width: auto; cursor: pointer">
					协议内容 <span class="prevArg" @click="getTemUrl">预览协议内容</span>
				</div>
			</div>
		</div>
		<el-drawer
			title="协议预览"
			size="70%"
			:height="dialogHeight"
			:visible.sync="dialogTableVisible"
			:modal="true"
			:lock-scroll="true"
			:append-to-body="true"
		>
			<pdf :src="tempUrl" :page="curPage" @num-pages="getPageNum"></pdf>
			<div class="footer">
				<el-button-group class="page">
					<el-button
						@click="prevPage"
						icon="el-icon-arrow-left"
						:disabled="curPage === 1"
						>上一页</el-button
					>
					<el-button
						@click="nextPage"
						icon="el-icon-arrow-right"
						:disabled="!totalPage || curPage === totalPage"
						>下一页</el-button
					>
				</el-button-group>
			</div>
		</el-drawer>
		<!-- 弹框 -->
		<SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
	</div>
</template>

<script>
import request from '@/found/utils/request';
import pdf from 'vue-pdf';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';
import Storage from '@/found/utils/storage';
import { log } from 'mathjs';
import { remove } from 'nprogress';

export default {
  name: 'rule_config',
  props: {
    autosize: Object,
    title: String,
    value: Array,
    disabled: Boolean,
    getFormData: Function,
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeIndex: '1',
      type: '', // 选择类型
      prolist: [], // 搭赠-产品列表
      standard: '1', // 搭赠-计算标准
      rewardOptions: [
        {
          value: '1',
          label: '红包',
        },
        {
          value: '2',
          label: '实物',
        },
      ],

      standList: [
        {
          num: '',
          reward: '',
          total: '2000',
          standproduct: [],
          money: '',
          bottleNum: '',
        },
      ], // 搭赠-计算标准产品列表
      standIndex: 0, // 搭赠-计算标准产品索引
      textarea: '', // 搭赠-分利规则描述
      policyList: [], // 包量-规则配置
      quantityProlist: [], // 包量-产品
      check: '1', // 包量-是否考核
      jtList: [], // 包量-阶梯table
      rewardTypeList: [
        {
          value: '1',
          label: '现金',
        },
        {
          value: '2',
          label: '实物',
        },
      ], // 包量-奖励类型
      rewardCycle: [
        {
          value: '2',
          label: '按半年返',
        },
        {
          value: '1',
          label: '按年度返',
        },
        {
          value: '3',
          label: '按季度返',
        },
      ],

      displyJtList: [], // 陈列-阶梯
      displayProlist: [], // 陈列产品
      timeRadio: '1', // 陈列-上传规则配置
      selectcycleVal: '1',
      selectcycle: [
        {
          value: '1',
          label: '周',
        },
        {
          value: '2',
          label: '月',
        },
      ], // 陈列-自定时间
      selectcycleDay: '', // 陈列-星期
      selectcycleDayOptions: [
        {
          value: '1',
          label: '星期一',
          flag: false,
        },
        {
          value: '2',
          label: '星期二',
          flag: false,
        },
        {
          value: '3',
          label: '星期三',
          flag: false,
        },
        {
          value: '4',
          label: '星期四',
          flag: false,
        },
        {
          value: '5',
          label: '星期五',
          flag: false,
        },
        {
          value: '6',
          label: '星期六',
          flag: false,
        },
        {
          value: '7',
          label: '星期日',
          flag: false,
        },
      ], // 陈列-星期
      limitNum: '', // 陈列-上传次数
      sheetNum: '', // 陈列-上传张数
      timeList: [], // 陈列-时间列表
      headerObj: {
        loginUserToken: Storage.l.get('TokenKey'),
      },
      displaySampleGraphs: [],
      dialogVisible: false,
      dialogImageUrl: '',
      displayladderTable: [],
      displayProRewardIndex: 0,
      blProRewardIndex: 0,
      pileSampleUrl: [],
      shelfSampleUrl: [],
      dialogTableVisible: false,
      dialogHeight: '600px',
      tempUrl: '',
      tempUrlName: '',
      urlsrc: '',
      curPage: 1, // 当前页
      totalPage: 1, // 总页数
      passRate: null,
      days: [],
      selectDay: [],
    };
  },
  components: {
    SelectConfig,
    pdf,
  },
  watch: {
    timeRadio(val) {
      this.timeList = [];
      this.limitNum = 0;
      this.sheetNum = 0;
    },
    limitNum: {
      immediate: true,
      deep: true,
      handler(oldVal, newVal) {
        if (oldVal !== newVal) {
          this.selectcycleDayOptions.forEach((item) => { item.flag = false; });
          this.days.forEach((item) => { item.flag = false; });
          this.selectDay = [];
        }
        console.log(oldVal);
        console.log(newVal);
      },
    },
    selectcycleVal: {
      immediate: true,
      deep: true,
      handler(oldVal, newVal) {
        this.days.forEach((item) => { item.flag = false; });
        this.selectcycleDayOptions.forEach((item) => { item.flag = false; });
        this.selectDay = []; this.limitNum = 0; this.sheetNum = 0;
        console.log(this.selectDay);
        console.log(this.days);
        console.log(this.selectcycleDayOptions);
      },
    },
  },
  created() {
    this.getDays();
  },
  methods: {
    getDays() {
      const now = new Date();
      const year = now.getFullYear();
      let month = now.getMonth() + 1; // 月份从0开始，所以要加1
      if (month < 10) {
        month = `0${month}`;
      }
      const daysInMonth = new Date(year, month, 0).getDate();
      console.log(daysInMonth);
      const dates = [];
      for (let day = 1; day <= daysInMonth; day += 1) {
        console.log(day);
        dates.push({ flag: false, val: `${day}` });
        // if (day < 10) {
        //   dates.push({ flag: false, val: `0${day}` });
        // } else {
        //   dates.push({ flag: false, val: `${day}` });
        // }
      }
      this.days = dates;
      console.log(dates);
    },
    // 固定时间选择日或者星期
    choiceDay(index, type) {
      if (this.limitNum === 0 || !this.limitNum) {
        this.$message.error('请输入上传次数');
        return false;
      }
      if (type === 'month') {
        if (this.days[index].flag) {
          this.days[index].flag = false;
          this.selectDay = this.selectDay.filter(
            (item) => item !== this.days[index].val,
          );
        } else {
          if (this.selectDay.length >= this.limitNum) {
            this.$message.error('上传时间与上传次数不一致');
            return false;
          }
          this.days[index].flag = true;
          this.selectDay.push(this.days[index].val);
        }

        console.log(this.selectDay);
      }
      if (type === 'day') {
        if (this.selectcycleDayOptions[index].flag) {
          this.selectcycleDayOptions[index].flag = false;
          this.selectDay = this.selectDay.filter(
            (item) => item !== this.selectcycleDayOptions[index].value,
          );
        } else {
          if (this.selectDay.length >= this.limitNum) {
            this.$message.error('上传时间与上传次数不一致');
            return false;
          }
          this.selectcycleDayOptions[index].flag = true;
          this.selectDay.push(this.selectcycleDayOptions[index].value);
        }

        console.log(this.selectDay);
      }
    },
    // 获取协议总页数
    getPageNum(val) {
      console.log(val);
      this.totalPage = val;
    },
    // 上一页
    prevPage() {
      if (this.curPage > 1) {
        this.curPage -= 1;
      }
    },
    // 下一页
    nextPage() {
      if (this.curPage < this.totalPage) {
        this.curPage += 1;
      }
    },
    // tab切换
    tabClick() {
      console.log(this.activeIndex);
      // if(this.activeIndex){
      //   console.log
      // }
      // this.getTemUrl();
    },

    // 新增弹窗
    openModal(type, index) {
      console.log(type);
      console.log(index);

      let params = {};
      if (
        type === 'product'
					|| type === 'quantity'
					|| type === 'standproduct'
					|| type === 'dispalypro'
					|| type === 'displayProReward'
					|| type === 'blProReward'
      ) {
        params = {
          functionCode: 'product_list',
          data: [],
          selectionList: [],
          idKey: 'productCode',
          paramData: {
            enableStatus: '009',
          },
        };
        if (type === 'standproduct') {
          this.standIndex = index || 0;
        } else if (type === 'displayProReward') {
          this.displayProRewardIndex = index || 0;
        } else if (type === 'blProReward') {
          this.blProRewardIndex = index || 0;
        } else if (type === 'quantity') {
          if (this.policyList.length === 0) {
            this.$message.error('请添加包量政策规则配置');
            return;
          }
        } else if (type === 'dispalypro') {
          if (this.displyJtList.length === 0) {
            this.$message.error('请添加陈列政策规则配置');
            return;
          }
        }
        this.$refs.selectConfig.modalConfig.title = '选择产品';
      } else if (type === 'policy') {
        params = {
          functionCode: 'treety_modules_addConfig',
          data: [],
          selectionList: [],
          idKey: 'productLevelCode',
          noReset: true,
          requestType: 'GET',
          paramData: {
            productLevelType: 'ladder',
            enableStatus: '009',
            ladderType: 'quantify',
          },
        };

        this.$refs.selectConfig.modalConfig.title = '选择阶梯';
      } else if (type === 'display') {
        params = {
          functionCode: 'treety_modules_addConfig',
          data: [],
          selectionList: [],
          idKey: 'productLevelCode',
          noReset: true,
          requestType: 'GET',
          paramData: {
            productLevelType: 'ladder',
            enableStatus: '009',
            ladderType: 'display',
          },
        };
        this.$refs.selectConfig.modalConfig.title = '选择陈列阶梯';
      }
      this.type = type;
      this.$refs.selectConfig.openSelectModal(params);
    },
    delTag(row, index) {
      console.log(row);
      console.log(index);
      console.log(this.jtList);
      const halfF = '2';
      const quarterF = '3';
      const allyearF = '1';
      console.log(this.jtList[index].ladderTable[0].rewardPeriod);
      const list = this.jtList[index].ladderTable[0].rewardPeriod;
      console.log(list.indexOf(halfF));
      if (list.indexOf(halfF) <= -1) {
        this.jtList[index].halfFlag = false;
      }
      if (list.indexOf(quarterF) <= -1) {
        this.jtList[index].quarterFlag = false;
      }
      if (list.indexOf(allyearF) <= -1) {
        this.jtList[index].allyearFlag = false;
      }
    },
    judgeCol(row, index) {
      console.log(row);
      console.log(index);
      console.log(this.jtList);
      const halfF = '2';
      const quarterF = '3';
      const allyearF = '1';
      console.log(this.jtList[index].ladderTable[0].rewardPeriod);
      const list = this.jtList[index].ladderTable[0].rewardPeriod;
      console.log(list.indexOf(halfF));
      if (list.indexOf(halfF) <= -1) {
        this.jtList[index].halfFlag = false;
        if (this.jtList[index].halfYearMoney) {
          this.jtList[index].halfYearMoney = null;
        }
      } else {
        this.jtList[index].halfFlag = true;
      }
      if (list.indexOf(quarterF) <= -1) {
        this.jtList[index].quarterFlag = false;
        if (this.jtList[index].quarterMoney) {
          this.jtList[index].quarterMoney = null;
        }
      } else {
        this.jtList[index].quarterFlag = true;
      }
      if (list.indexOf(allyearF) <= -1) {
        this.jtList[index].allyearFlag = false;
        if (this.jtList[index].allYearMoney) {
          this.jtList[index].allYearMoney = null;
        }
      } else {
        this.jtList[index].allyearFlag = true;
      }
    },
    judgeDislpayCol(row, index) {
      const halfF = '2';
      const quarterF = '3';
      const allyearF = '1';
      console.log(row);
      console.log(index);
      console.log(this.displayladderTable[index].rewardPeriod);
      const list = this.displayladderTable[index].rewardPeriod;
      console.log(list.indexOf(halfF));
      if (list.indexOf(halfF) <= -1) {
        this.displayladderTable[index].halfFlag = false;
        if (this.displayladderTable[index].halfYearMoney) {
          this.displayladderTable[index].halfYearMoney = null;
        }
      } else {
        this.displayladderTable[index].halfFlag = true;
      }
      if (list.indexOf(quarterF) <= -1) {
        this.displayladderTable[index].quarterFlag = false;
        if (this.displayladderTable[index].quarterMoney) {
          this.displayladderTable[index].quarterMoney = null;
        }
      } else {
        this.displayladderTable[index].quarterFlag = true;
      }
      if (list.indexOf(allyearF) <= -1) {
        this.displayladderTable[index].allyearFlag = false;
        if (this.displayladderTable[index].allYearMoney) {
          this.displayladderTable[index].allYearMoney = null;
        }
      } else {
        this.displayladderTable[index].allyearFlag = true;
      }
      this.$forceUpdate();
    },
    // 选择回调
    onGetSelect(val) {
      const value = [];
      console.log(this.type);
      if (this.type === 'product') {
        const data = this.getReset(val, this.prolist, 'productCode');
        console.log(data);
        this.prolist = data;
        this.$emit('change', val);
      } else if (this.type === 'standproduct') {
        console.log(this.standIndex);
        const data = this.getReset(
          val,
          this.standList[this.standIndex].standproduct,
          'productCode',
        );
        this.standList[this.standIndex].standproduct = data;
        console.log(this.standList);
      } else if (this.type === 'policy') {
        this.policyList = val;
      } else if (this.type === 'quantity') {
        const data = this.getReset(val, this.quantityProlist, 'productCode');
        console.log(data);
        this.quantityProlist = data;
        if (this.jtList) {
          this.jtList.forEach((item) => {
            item.quantityProlist = JSON.parse(
              JSON.stringify(item.quantityProlist.concat(val)),
            );
          });
        }
        console.log(this.jtList);
      } else if (this.type === 'display') {
        this.displyJtList = val;
      } else if (this.type === 'dispalypro') {
        const data = this.getReset(val, this.displayProlist, 'productCode');
        console.log(data);
        this.displayProlist = data;
      } else if (this.type === 'displayProReward') {
        console.log('index----');
        console.log(this.displayProRewardIndex);
        const data = this.getReset(
          val,
          this.displayladderTable[this.displayProRewardIndex].giftProductVos,
          'productCode',
        );
        console.log(data);
        console.log(this.displayladderTable);
        this.displayladderTable[this.displayProRewardIndex].giftProductVos =						data;
      } else if (this.type === 'blProReward') {
        console.log('indexBl----');
        console.log(this.blProRewardIndex);
        console.log(this.jtList);
        const data = this.getReset(
          val,
          this.jtList[this.blProRewardIndex].ladderTable[0].giftProductVos,
          'productCode',
        );
        console.log(data);
        this.jtList[this.blProRewardIndex].ladderTable[0].giftProductVos = data;
      }
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },

    // 删除所选产品
    handleClose(item, type, index) {
      console.log(item);
      if (type === 'product') {
        const data = this.prolist.filter(
          (v) => v.productCode !== item.productCode,
        );
        this.prolist = data;
      } else if (type === 'quantity') {
        const data = this.quantityProlist.filter(
          (v) => v.productCode !== item.productCode,
        );
        this.quantityProlist = data;
        const jtProList = this.jtList;
        jtProList.forEach((jitem, jindex) => {
          jitem.quantityProlist.forEach((citem, cindex) => {
            if (citem.productCode === item.productCode) {
              jtProList[jindex].quantityProlist.splice(cindex, 1);
            }
          });
        });
        if (data.length === 0) {
          this.jtList = [];
        }
        console.log(this.jtList);
        this.$forceUpdate();
      } else if (type === 'dispalypro') {
        const data = this.displayProlist.filter(
          (v) => v.productCode !== item.productCode,
        );
        this.displayProlist = data;
      } else if (type === 'displayProReward') {
        console.log(index);
        console.log('前');
        console.log(this.displayladderTable[index].giftProductVos);
        const data = this.displayladderTable[index].giftProductVos.filter(
          (v) => v.productCode !== item.productCode,
        );
        this.displayladderTable[index].giftProductVos = data;
        console.log('后');
        console.log(this.displayladderTable[index].giftProductVos);
      }
    },
    // 计算标准添加行
    addRow() {
      this.standList.push({
        num: '',
        reward: '',
        standproduct: [],
        money: '',
        bottleNum: '',
      });
    },
    // 计算标准删除行
    removeRow(index) {
      this.standList.splice(index, 1);
    },
    // 计算标准删除产品
    handleStandClose(item, index) {
      console.log(item);
      console.log(index);
      const data = this.standList[index].standproduct.filter(
        (v) => v.productCode !== item.productCode,
      );
      this.standList[index].standproduct = data;
    },
    // 预览协议内容
    async prevArgeement() {
      console.log(this.tempUrl);
      this.curPage = 1;
      if (!this.tempUrl) {
        this.$message.warning('没有可查看的协议！');
        return false;
      }
      this.dialogTableVisible = true;
    },
    // 包量-奖励类型判断
    getSelectVal(index, row) {
      console.log(index);
      console.log(row);
      if (row.rewardType === '1') {
        this.jtList[index].ladderTable[0].rewardName = '现金奖励';
      }
      if (row.rewardType === '2') {
        this.jtList[index].ladderTable[0].rewardName = '实物奖励';
      }
      console.log(index);
      console.log(this.jtList);
    },
    // 包量-增加阶梯
    addJtRow(index, row) {
      console.log(this.jtList);
      if (!this.quantityProlist.length) {
        this.$message.error('请选择产品');
        return;
      }
      if (this.check === '1') {
        this.jtList.push({
          ladderTable: [
            {
              ratio: '',
              rewardName: '',
              rewardPeriod: '',
              rewardType: '',
              giftProductVos: [],
            },
          ],
          quantityProlist: JSON.parse(JSON.stringify(this.quantityProlist)),
          halfFlag: false,
          quarterFlag: false,
          allyearFlag: false,
          hbList: [
            {
              proList: this.quantityProlist,
              halfYearMoney: '',
              allYearMoney: '',
              quarterMoney: '',
            },
          ],
        });
      } else {
        this.jtList.push({
          ladderTable: [
            {
              ratio: '',
              rewardName: '',
              rewardPeriod: '',
              rewardType: '',
              giftProductVos: [],
            },
          ],
          quantityProlist: JSON.parse(JSON.stringify(this.quantityProlist)),
          halfFlag: false,
          quarterFlag: false,
          allyearFlag: false,
        });
      }
      // this.jtList.push({
      //   ladderTable: [{
      //     ratio: '',
      //     rewardName: '',
      //     rewardPeriod: '',
      //     rewardType: '',
      //     giftProductVos: [],
      //   }],
      //   quantityProlist: JSON.parse(JSON.stringify(this.quantityProlist)),
      //   halfFlag: true,
      //   quarterFlag: true,
      //   allyearFlag: true,
      // });
      console.log('this.jtList');
      console.log(this.jtList);
      console.log(this.quantityProlist);
    },
    // 包量-删除阶梯
    removeJtRow(index) {
      console.log(index);
      this.jtList.splice(index, 1);
    },
    // 陈列-添加指定个数
    addElements(arr, num, element) {
      for (let i = 0; i < num; i += 1) {
        arr.push(JSON.parse(JSON.stringify(element)));
      }
    },
    // 动态添加行数
    bluraddElements(event) {
      const num = event.target.value;
      this.timeList = [];
      const obj = {
        start: '',
        end: '',
      };
      if (this.timeRadio === '1') {
        this.addElements(this.timeList, num, obj);
      }

      console.log(this.timeList);
    },
    // 陈列奖励添加阶梯
    dispalyAdd() {
      const obj = {
        ratio: '',
        rewardType: '',
        giftProductVos: [],
        rewardName: '',
        rewardPeriod: [],
        rewardNum: '',
        halfFlag: false,
        quarterFlag: false,
        allyearFlag: false,
      };
      this.displayladderTable.push(obj);
    },
    getDisplaySelectVal(index, row) {
      if (row.rewardType === '1') {
        this.displayladderTable[index].rewardName = '现金奖励';
      }
      if (row.rewardType === '2') {
        this.displayladderTable[index].rewardName = '实物奖励';
        this.displayladderTable[index].giftProductVos = [];
      }
      console.log(index);
      console.log(row);
      console.log(this.displayladderTable[index]);
    },
    handlePitem(item, index) {
      console.log(item);
      console.log(index);
      // const data = this.displayladderTable[this.displayProRewardIndex].productList.filter(
      //     (v) => v.productCode !== item.productCode,
      // );
      // this.displayladderTable[this.displayProRewardIndex].productList = data;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList, type) {
      console.log('removefile', file);
      console.log('type', type);
      if (type === 'shelf') {
        this.shelfSampleUrl.splice(this.shelfSampleUrl.indexOf(file.fileName));
      } else if (type === 'pile') {
        this.pileSampleUrl.splice(this.pileSampleUrl.indexOf(file.fileName));
      }
    },
    handleDownload(file) {
      console.log('download', file);
    },
    handleSuccess(res, file, fileList, type) {
      console.log(type);
      console.log('图片', res);
      if (res.code === 200 && res.success) {
        console.log(res);
        res.result.forEach((v) => {
          if (type === 'shelf') {
            this.shelfSampleUrl.push({
              url: v.url,
              fileName: v.fileName,
            });
          } else if (type === 'pile') {
            this.pileSampleUrl.push({
              url: v.url,
              fileName: v.fileName,
            });
          }
        });
      }
    },

    // 清除选择
    clearFn(e) {
      e.stopPropagation();
      this.$emit('change');
    },
    async getTemUrl() {
      this.curPage = 1;
      const loading = this.$loading({
        lock: true,
        text: '模版加载中,请稍等...',
        spinner: 'el-icon-loading',
        fullscreen: false,
      });
      const params = {
        type: this.activeIndex, // 1，随单搭赠，2，包量政策，3，成列政策

        products: [], // 产品列表
        displayReqVo: {
          ladderCode: '', // 阶梯编码
          timeRadio: '', // 固定，自定
          dateMode: '', // 日期周期：1，每周，2，每月
          limitNum: 0, // 上传次数
          pileSampleUrl: [], // 堆头图
          sheetNum: 0, // 单次上传次数
          passRate: '', // 通过率
          shelfSampleUrl: [], // 货架图
          uploadReqVos: [],
          detailReqVos: [],
        },
        followReqVo: {
          content: '', // 搭赠分利规则描述
          mode: this.standard, // 1，单阶梯增，2多阶梯赠单阶梯：多阶梯
          detailReqVos: [],
        },
        objects: [],
        quantifyReqVo: {
          ladderCode: '', // 阶梯编码
          combineAssess: this.check, // 是否合并考核 0,1
          detailReqVos: [],
        },
      };
      // 包量政策
      if (this.activeIndex === '2') {
        this.quantityProlist.forEach((v) => {
          params.products.push({
            productCode: v.productCode,
            productName: v.productName,
            productLevelCode: v.productLevelCode,
            productLevelName: v.productLevelName,
          });
        });
        params.quantifyReqVo.ladderCode =						this.policyList.length > 0 ? this.policyList[0].ladderCode : '';
        this.jtList.forEach((bv) => {
          params.quantifyReqVo.detailReqVos.push({
            ratio: bv.ladderTable[0].ratio, // 阶梯比例
            rewardName: bv.ladderTable[0].rewardName, // 奖励名称
            rewardPeriods: bv.ladderTable[0].rewardPeriod, // 多值","分隔，按年，按季，按半年,
            rewardType: bv.ladderTable[0].rewardType, // 奖励类型  1,现金，2.实物
            giftProductVos: bv.ladderTable[0].giftProductVos,
            rebateVos: this.check === '1' ? bv.hbList : bv.quantityProlist,
          });
        });
      }
      // 陈列政策
      if (this.activeIndex === '3') {
        this.displayProlist.forEach((v) => {
          params.products.push({
            productCode: v.productCode,
            productName: v.productName,
            productLevelCode: v.productLevelCode,
            productLevelName: v.productLevelName,
          });
        });
        this.timeList.forEach((ditem, dindex) => {
          params.displayReqVo.uploadReqVos.push({
            indexNo: dindex,
            startNum: ditem.start,
            endNum: ditem.end,
          });
        });
        this.displayladderTable.forEach((jitem, jindex) => {
          params.displayReqVo.detailReqVos.push({
            ratio: jitem.ratio, // 达成比例
            rewardName: jitem.rewardName, // 奖励名称
            allYearMoney: jitem.allYearMoney, // 季度达成数量
            halfYearMoney: jitem.halfYearMoney, // 季度达成数量
            quarterMoney: jitem.quarterMoney, // 季度达成数量
            rewardPeriods: jitem.rewardPeriod, // 周期 多值","分隔，按年，按季，按半年
            rewardType: jitem.rewardType, // 奖励类型
            giftProductVos: jitem.giftProductVos,
          });
        });
        params.displayReqVo.ladderCode =						this.displyJtList.length > 0 ? this.displyJtList[0].ladderCode : '';
        params.displayReqVo.uploadConfig = this.timeRadio;
        params.displayReqVo.dateMode = this.selectcycleVal;
        params.displayReqVo.limitNum = this.limitNum;
        params.displayReqVo.pileSampleUrl = this.pileSampleUrl;
        params.displayReqVo.sheetNum = this.sheetNum;
        params.displayReqVo.shelfSampleUrl = this.shelfSampleUrl;
        params.displayReqVo.passRate = parseFloat(this.passRate);
      }

      await request
        .post('/cps/cpsagreetemplate/getAgreeTemplateUrl', params)
        .then((res) => {
          console.log(res);
          if (res.success) {
            loading.close();
            this.dialogTableVisible = true;
            this.tempUrl = `/upload/downloadController/download?objectName=${res.message}`;
            this.tempUrlName = res.message;
          }
        })
        .catch((err) => {
          console.log(err);
          loading.close();
        });
    },
  },
};
</script>

<style lang="less" scoped>
	.titlerule {
		width: 100%;
		font-size: 13px;
		font-weight: 500;
	}
	.margin10 {
		margin-top: 10px;
	}
	.proList {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.pro_label {
			font-size: 14px;
			color: #606266;
			width: 80px;
		}
	}
	.stand {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.pro_label {
			font-size: 16px;
			color: #333;
			width: 120px;
		}
	}
	.stand_item {
		display: flex;
		align-items: center;
		width: 100% !important;
		margin-bottom: 8px;
		div {
			display: flex;
			align-items: center;
			margin-right: 10px;
		}
		.stand_icon {
			margin-left: auto;
		}
	}
	.prevArg {
		font-size: 12px;
		color: #c34231;
		margin-left: 10px;
	}
	.policy {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		div.last {
			display: flex;
			align-items: center;
			cursor: pointer;
			span {
				color: #c34231;
				font-size: 14px;
			}
		}
	}
	.jtclass {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.el-table {
			margin-right: 30px;
		}
	}
	.dispaly_rules {
		width: 100%;
		padding: 20px;
		box-sizing: border-box;
	}
	.customized_time {
		width: 100%;

		.cycle {
			display: flex;
			align-items: center;
			flex: 1;
			span {
				margin: 0 10px;
				display: inline-block;
			}
		}
		.cycle_content {
			width: auto;
			border: 1px solid #dddfe6;
			padding: 10px;
			box-sizing: border-box;
			.cycle_row {
				width: 100%;
				.cycle_content_item {
					margin: 10px 0;
					display: flex;
					flex: auto;
					.el-input {
						display: flex;
					}
					.el-input-group__append {
						display: inline-block;
					}
					.apend {
						display: inline-block;
						// height: 30px;
						line-height: 30px;
					}
				}
			}
		}
	}
	.weekly {
		display: flex;
		margin: 10px 0;
	}
	/deep/.el-upload--picture-card {
		width: 80px !important;
		height: 80px !important;
		line-height: 90px;
	}
	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 80px !important;
		height: 80px !important;
	}
	/deep/.el-upload-list--picture-card .el-upload-list__item-actions {
		i {
			font-size: 16px;
		}
	}
	.cost-select {
		position: relative;
		border: 1px solid #dddfe6;
		border-radius: 5px;
		padding: 5px;

		/deep/ .el-input__inner {
			padding-right: 30px;
		}
		.el-tag {
			margin: 0 5px;
		}
		.clear-icon {
			width: 25px;
			height: 100%;
			position: absolute;
			right: 5px;
			top: 0;
			text-align: center;
			color: #c0c4cc;
			transition: all 0.3s;
			padding-top: 1px;
			display: none;

			.el-icon-circle-close {
				width: 100%;
				font-size: 14px;
				cursor: pointer;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
			}
		}

		&:hover {
			.clear-icon {
				display: block;
			}
		}
	}
	.footer {
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 20px;
		width: 100%;
		height: 73px;
		z-index: 999;
		border-top: 1px solid #e4e4e4;
		justify-content: flex-end;
		.page {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
		.download {
			position: absolute;
			right: 50px;
			.el-button {
				margin-left: 8px;
			}
		}
	}
	/deep/.el-drawer__header {
		color: #333;
		font-weight: bolder;
		span {
			font-size: 20px;
		}
	}
	.days_list {
		width: 80%;
		margin-left: 40px;
		display: flex;
		flex-wrap: wrap;
		.days_item {
			padding: 0 20px;
			box-sizing: border-box;
			height: 30px;
			margin-right: 10px;
			margin-bottom: 10px;
			cursor: pointer;
			border: 1px solid #606266;
			text-align: center;
			line-height: 30px;
			font-size: 14px;
			color: #333;
			&.active {
				background-color: #c34231;
				color: white;
				border: 1px solid #c34231;
			}
		}
	}
</style>
