var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "titlerule" }, [
        _vm._v("协议包含政策类型(单选)"),
      ]),
      _c(
        "div",
        { staticClass: "tabs" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.activeIndex,
                callback: function ($$v) {
                  _vm.activeIndex = $$v
                },
                expression: "activeIndex",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "随单搭赠", name: "1" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal("product")
                        },
                      },
                    },
                    [_vm._v("添加产品")]
                  ),
                  _c("div", { staticClass: "proList margin10" }, [
                    _c("div", { staticClass: "pro_label" }, [_vm._v("产品")]),
                    _c(
                      "div",
                      {
                        staticClass: "cost-select el-textarea el-input--small",
                        staticStyle: { "min-height": "32px" },
                      },
                      _vm._l(_vm.prolist, function (item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            attrs: { closable: "" },
                            on: {
                              close: function ($event) {
                                return _vm.handleClose(item, "product")
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.productName || ""))]
                        )
                      }),
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "stand margin10" },
                    [
                      _c(
                        "el-form",
                        { ref: "form", attrs: { "label-width": "100px" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "搭赠计算标准" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.standard,
                                    callback: function ($$v) {
                                      _vm.standard = $$v
                                    },
                                    expression: "standard",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("单阶梯赠"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("多阶梯赠"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "stand_list" }, [
                    _c(
                      "div",
                      { staticClass: "stand_list_item" },
                      [
                        _c(
                          "el-row",
                          _vm._l(_vm.standList, function (item, index) {
                            return _c(
                              "el-col",
                              {
                                key: item.id,
                                staticClass: "stand_item",
                                attrs: { span: 24 },
                              },
                              [
                                _c("div", [_vm._v("入库满")]),
                                _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        size: "mini",
                                        inline: true,
                                      },
                                      model: {
                                        value: item.num,
                                        callback: function ($$v) {
                                          _vm.$set(item, "num", $$v)
                                        },
                                        expression: "item.num",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", [_vm._v("箱,奖励")]),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择",
                                          inline: true,
                                          size: "mini",
                                        },
                                        model: {
                                          value: item.reward,
                                          callback: function ($$v) {
                                            _vm.$set(item, "reward", $$v)
                                          },
                                          expression: "item.reward",
                                        },
                                      },
                                      _vm._l(
                                        _vm.rewardOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                item.reward == 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cost-select el-textarea el-input--small",
                                        staticStyle: {
                                          "min-height": "32px",
                                          width: "400px",
                                          "word-wrap": "break-word",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openModal(
                                              "standproduct",
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        item.standproduct.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "flex-wrap": "wrap",
                                                },
                                              },
                                              _vm._l(
                                                item.standproduct,
                                                function (pitem, pindex) {
                                                  return _c(
                                                    "el-tag",
                                                    {
                                                      key: pindex,
                                                      attrs: { closable: "" },
                                                      on: {
                                                        close: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleStandClose(
                                                            pitem,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          pitem.productName ||
                                                            ""
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "10px",
                                                  width: "200px",
                                                },
                                              },
                                              [_vm._v(" 请选择商品 ")]
                                            ),
                                      ]
                                    )
                                  : _vm._e(),
                                item.reward == 1
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            inline: true,
                                            size: "mini",
                                          },
                                          model: {
                                            value: item.rewardNum,
                                            callback: function ($$v) {
                                              _vm.$set(item, "rewardNum", $$v)
                                            },
                                            expression: "item.rewardNum",
                                          },
                                        }),
                                        _vm._v("元 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.reward == 2
                                  ? _c(
                                      "div",
                                      [
                                        _vm._v(" 各 "),
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            inline: true,
                                            size: "mini",
                                          },
                                          model: {
                                            value: item.rewardNum,
                                            callback: function ($$v) {
                                              _vm.$set(item, "rewardNum", $$v)
                                            },
                                            expression: "item.rewardNum",
                                          },
                                        }),
                                        _vm._v("瓶 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "stand_icon" }, [
                                  _vm.standard == "2" &&
                                  _vm.standList.length > 1 &&
                                  index > 0
                                    ? _c("i", {
                                        staticClass: "el-icon-remove-outline",
                                        staticStyle: {
                                          "font-size": "24px",
                                          color: "#c34231",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeRow(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.standard == "2" && index == 0
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus-outline",
                                        staticStyle: {
                                          "font-size": "24px",
                                          color: "#c34231",
                                        },
                                        on: { click: _vm.addRow },
                                      })
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "margin10" },
                    [
                      _c("div", [_vm._v("搭赠分利规则描述")]),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 10,
                          placeholder: "请输入搭赠分利规则描述",
                          resize: "none",
                        },
                        model: {
                          value: _vm.textarea,
                          callback: function ($$v) {
                            _vm.textarea = $$v
                          },
                          expression: "textarea",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "包量政策", name: "2" } },
                [
                  _c("div", { staticClass: "policy" }, [
                    _c("div", [_vm._v("政策规则配置")]),
                    _c(
                      "div",
                      {
                        staticClass: "last",
                        on: {
                          click: function ($event) {
                            return _vm.openModal("policy")
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-circle-plus-outline",
                          staticStyle: {
                            "font-size": "24px",
                            color: "#c34231",
                          },
                        }),
                        _c("span", [_vm._v("添加阶梯")]),
                      ]
                    ),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "margin10" },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.policyList, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "阶梯编码",
                                  prop: "ladderCode",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "阶梯名称",
                                  prop: "ladderName",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "阶梯类型",
                                  prop: "ladderType",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "总销量",
                                  prop: "yearSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度1",
                                  prop: "januarySaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度2",
                                  prop: "februarySaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度3",
                                  prop: "marchSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度4",
                                  prop: "aprilSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度5",
                                  prop: "maySaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度6",
                                  prop: "juneSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度7",
                                  prop: "julySaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度8",
                                  prop: "augustSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度9",
                                  prop: "septemberSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度10",
                                  prop: "octoberSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度11",
                                  prop: "novemberSaleNum",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "月度12",
                                  prop: "decemberSaleNum",
                                  align: "center",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "margin10",
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal("quantity")
                        },
                      },
                    },
                    [_vm._v("添加产品")]
                  ),
                  _c("div", { staticClass: "proList margin10" }, [
                    _c("div", { staticClass: "pro_label" }, [_vm._v("产品")]),
                    _c(
                      "div",
                      {
                        staticClass: "cost-select el-textarea el-input--small",
                        staticStyle: { "min-height": "32px" },
                      },
                      _vm._l(_vm.quantityProlist, function (item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            attrs: { closable: "" },
                            on: {
                              close: function ($event) {
                                return _vm.handleClose(item, "quantity")
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.productName || ""))]
                        )
                      }),
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "policy margin10" },
                    [
                      _c(
                        "el-form",
                        { ref: "form", attrs: { "label-width": "100px" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否合并考核" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.check,
                                    callback: function ($$v) {
                                      _vm.check = $$v
                                    },
                                    expression: "check",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "1", name: "1" } },
                                    [_vm._v("是")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "0", name: "0" } },
                                    [_vm._v("否")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "last",
                          on: {
                            click: function ($event) {
                              return _vm.addJtRow()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                            staticStyle: {
                              "font-size": "24px",
                              color: "#c34231",
                            },
                          }),
                          _c("span", [_vm._v("添加阶梯")]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.jtList, function (item, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "font-weight": "500",
                          },
                        },
                        [_vm._v(" 阶梯" + _vm._s(index + 1) + " ")]
                      ),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "jtclass" },
                          [
                            _c(
                              "el-table",
                              { attrs: { data: item.ladderTable, border: "" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "阶梯达成比例(%)",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                placeholder:
                                                  "请输入阶梯达成比例",
                                              },
                                              model: {
                                                value: scope.row.ratio,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "ratio",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.ratio",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "奖励类型", align: "center" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  clearable: "",
                                                  placeholder: "请选择",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.getSelectVal(
                                                      index,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.rewardType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "rewardType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.rewardType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.rewardTypeList,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                            scope.row.rewardType == "2"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                      color: "#c34231",
                                                      "margin-top": "10px",
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openModal(
                                                          "blProReward",
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 添加实物奖励 ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "奖励名称", align: "center" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.rewardType == "1"
                                              ? _c("span", [_vm._v("现金奖励")])
                                              : scope.row.giftProductVos
                                              ? _c(
                                                  "span",
                                                  _vm._l(
                                                    scope.row.giftProductVos,
                                                    function (pitem, pindex) {
                                                      return _c(
                                                        "el-tag",
                                                        {
                                                          key: pindex,
                                                          attrs: {
                                                            closable: "",
                                                          },
                                                          on: {
                                                            close: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleClose(
                                                                pitem,
                                                                "blProReward",
                                                                scope.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              pitem.productName ||
                                                                ""
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _c("span"),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "奖励周期(可多选)",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  multiple: "",
                                                  clearable: "",
                                                  placeholder: "请选择奖励周期",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.judgeCol(
                                                      scope.row,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.rewardPeriod,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "rewardPeriod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.rewardPeriod",
                                                },
                                              },
                                              _vm._l(
                                                _vm.rewardCycle,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.label,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeJtRow(index)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "margin10" },
                        [
                          _vm.check == 0
                            ? _c(
                                "el-table",
                                {
                                  attrs: {
                                    data: item.quantityProlist,
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品名称",
                                      prop: "productName",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品编码",
                                      prop: "productCode",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "季现金奖励(元/箱)",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              item.quarterFlag
                                                ? _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入季现金奖励",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.quarterMoney,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "quarterMoney",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.quarterMoney",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "半年现金奖励(元/箱)",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              item.halfFlag
                                                ? _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入半年现金奖励",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.halfYearMoney,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "halfYearMoney",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.halfYearMoney",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "年现金奖励(元/箱)",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              item.allyearFlag
                                                ? _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入年现金奖励",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.allYearMoney,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "allYearMoney",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.allYearMoney",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.check == 1
                            ? _c(
                                "el-table",
                                { attrs: { data: item.hbList, border: "" } },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "季现金奖励(元/箱)",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              item.quarterFlag
                                                ? _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入季现金奖励",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.quarterMoney,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "quarterMoney",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.quarterMoney",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "半年现金奖励(元/箱)",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              item.halfFlag
                                                ? _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入半年现金奖励",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.halfYearMoney,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "halfYearMoney",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.halfYearMoney",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "年现金奖励(元/箱)",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              item.allyearFlag
                                                ? _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入年现金奖励",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.allYearMoney,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "allYearMoney",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.allYearMoney",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "陈列政策", name: "3" } },
                [
                  _c("div", { staticClass: "policy" }, [
                    _c("div", [_vm._v("政策规则配置")]),
                    _c(
                      "div",
                      {
                        staticClass: "last",
                        on: {
                          click: function ($event) {
                            return _vm.openModal("display")
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-circle-plus-outline",
                          staticStyle: {
                            "font-size": "24px",
                            color: "#c34231",
                          },
                        }),
                        _c("span", [_vm._v("添加阶梯")]),
                      ]
                    ),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "margin10" },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.displyJtList, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "阶梯编码",
                                  prop: "ladderCode",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "阶梯名称",
                                  prop: "ladderName",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "货架陈列面数",
                                  prop: "displayNumber",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "堆头陈列面数",
                                  prop: "pileNumber",
                                  align: "center",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "margin10",
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal("dispalypro")
                        },
                      },
                    },
                    [_vm._v("添加产品")]
                  ),
                  _c("div", { staticClass: "proList margin10" }, [
                    _c("div", { staticClass: "pro_label" }, [_vm._v("产品")]),
                    _c(
                      "div",
                      {
                        staticClass: "cost-select el-textarea el-input--small",
                        staticStyle: { "min-height": "32px" },
                      },
                      _vm._l(_vm.displayProlist, function (item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            attrs: { closable: "" },
                            on: {
                              close: function ($event) {
                                return _vm.handleClose(item, "dispalypro")
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.productName || ""))]
                        )
                      }),
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "dispaly_rules margin10" }, [
                    _c(
                      "div",
                      { staticClass: "stand margin10" },
                      [
                        _c(
                          "el-form",
                          { ref: "form", attrs: { "label-width": "100px" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "上传规则配置" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.timeRadio,
                                      callback: function ($$v) {
                                        _vm.timeRadio = $$v
                                      },
                                      expression: "timeRadio",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("自定时间"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("固定时间上传"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "customized_time" }, [
                      _c(
                        "div",
                        { staticClass: "cycle" },
                        [
                          _c("span", [_vm._v("每")]),
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.selectcycleVal,
                                callback: function ($$v) {
                                  _vm.selectcycleVal = $$v
                                },
                                expression: "selectcycleVal",
                              },
                            },
                            _vm._l(_vm.selectcycle, function (item) {
                              return _c("el-option", {
                                key: item.label,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _c("span", [_vm._v("上传次数")]),
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "text",
                              placeholder: "请输入上传次数",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.bluraddElements($event)
                              },
                            },
                            model: {
                              value: _vm.limitNum,
                              callback: function ($$v) {
                                _vm.limitNum = $$v
                              },
                              expression: "limitNum",
                            },
                          }),
                          _c("span", [_vm._v(" 单次上传张数")]),
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "text",
                              placeholder: "请输入上传张数",
                            },
                            model: {
                              value: _vm.sheetNum,
                              callback: function ($$v) {
                                _vm.sheetNum = $$v
                              },
                              expression: "sheetNum",
                            },
                          }),
                          _c("span", [_vm._v(" 通过率")]),
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "text",
                              placeholder: "请输入通过率",
                            },
                            model: {
                              value: _vm.passRate,
                              callback: function ($$v) {
                                _vm.passRate = $$v
                              },
                              expression: "passRate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.timeRadio === "1"
                      ? _c("div", [
                          _c("div", { staticClass: "customized_time" }, [
                            _c(
                              "div",
                              { staticClass: "cycle_content margin10" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "cycle_row" },
                                  _vm._l(_vm.timeList, function (fre, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "cycle_content_item",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "第" +
                                              _vm._s(index + 1) +
                                              "次上传时间："
                                          ),
                                        ]),
                                        _vm.selectcycleVal === "2"
                                          ? _c(
                                              "el-input",
                                              {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  placeholder: "请输入内容",
                                                },
                                                model: {
                                                  value: fre.start,
                                                  callback: function ($$v) {
                                                    _vm.$set(fre, "start", $$v)
                                                  },
                                                  expression: "fre.start",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "apend",
                                                    attrs: { slot: "append" },
                                                    slot: "append",
                                                  },
                                                  [_vm._v("号")]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.selectcycleVal === "1"
                                          ? _c(
                                              "el-select",
                                              {
                                                attrs: { placeholder: "" },
                                                model: {
                                                  value: fre.start,
                                                  callback: function ($$v) {
                                                    _vm.$set(fre, "start", $$v)
                                                  },
                                                  expression: "fre.start",
                                                },
                                              },
                                              _vm._l(
                                                _vm.selectcycleDayOptions,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.label,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                        _c("span", [_vm._v("  至  ")]),
                                        _vm.selectcycleVal === "2"
                                          ? _c(
                                              "el-input",
                                              {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  placeholder: "请输入内容",
                                                },
                                                model: {
                                                  value: fre.end,
                                                  callback: function ($$v) {
                                                    _vm.$set(fre, "end", $$v)
                                                  },
                                                  expression: "fre.end",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "apend",
                                                    attrs: { slot: "append" },
                                                    slot: "append",
                                                  },
                                                  [_vm._v("号")]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.selectcycleVal === "1"
                                          ? _c(
                                              "el-select",
                                              {
                                                attrs: { placeholder: "" },
                                                model: {
                                                  value: fre.end,
                                                  callback: function ($$v) {
                                                    _vm.$set(fre, "end", $$v)
                                                  },
                                                  expression: "fre.end",
                                                },
                                              },
                                              _vm._l(
                                                _vm.selectcycleDayOptions,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.label,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.timeRadio === "2"
                      ? _c("div", [
                          _c("div", { staticClass: "customized_time" }, [
                            _c(
                              "div",
                              { staticClass: "cycle_content margin10" },
                              [
                                _c("div", { staticClass: "cycle_row" }, [
                                  _vm.selectcycleVal === "1"
                                    ? _c(
                                        "div",
                                        { staticClass: "cycle_content_item" },
                                        [
                                          _c("span", [
                                            _vm._v("每周上传时间："),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "days_list" },
                                            _vm._l(
                                              _vm.selectcycleDayOptions,
                                              function (ditem, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    class: {
                                                      days_item: true,
                                                      active: ditem.flag,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.choiceDay(
                                                          index,
                                                          "day"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(ditem.label) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectcycleVal === "2"
                                    ? _c(
                                        "div",
                                        { staticClass: "cycle_content_item" },
                                        [
                                          _c("span", [
                                            _vm._v("每月上传时间："),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "days_list" },
                                            _vm._l(
                                              _vm.days,
                                              function (ditem, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    class: {
                                                      days_item: true,
                                                      active: ditem.flag,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.choiceDay(
                                                          index,
                                                          "month"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(ditem.val) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "upPic" }, [
                      _c(
                        "div",
                        { staticClass: "weekly" },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "18px" } },
                            [_vm._v("货架示例图片：")]
                          ),
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                headers: _vm.headerObj,
                                action:
                                  "/upload/uploadController/uploadOssList",
                                "with-credentials": true,
                                "list-type": "picture-card",
                                "auto-upload": true,
                                "on-preview": _vm.handlePictureCardPreview,
                                "on-remove": function (file, fileList) {
                                  return _vm.handleRemove(
                                    file,
                                    fileList,
                                    "shelf"
                                  )
                                },
                                "on-success": function (
                                  response,
                                  file,
                                  fileList
                                ) {
                                  return _vm.handleSuccess(
                                    response,
                                    file,
                                    fileList,
                                    "shelf"
                                  )
                                },
                                "file-list": _vm.shelfSampleUrl,
                                accept:
                                  ".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "default" },
                                slot: "default",
                              }),
                            ]
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                visible: _vm.dialogVisible,
                                modal: false,
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  src: _vm.dialogImageUrl,
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "weekly" },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "18px" } },
                            [_vm._v("堆头示例图片：")]
                          ),
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                headers: _vm.headerObj,
                                action:
                                  "/upload/uploadController/uploadOssList",
                                "with-credentials": true,
                                "list-type": "picture-card",
                                "auto-upload": true,
                                "on-preview": _vm.handlePictureCardPreview,
                                "on-remove": function (file, fileList) {
                                  return _vm.handleRemove(
                                    file,
                                    fileList,
                                    "pile"
                                  )
                                },
                                "on-success": function (
                                  response,
                                  file,
                                  fileList
                                ) {
                                  return _vm.handleSuccess(
                                    response,
                                    file,
                                    fileList,
                                    "pile"
                                  )
                                },
                                "file-list": _vm.pileSampleUrl,
                                accept:
                                  ".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "default" },
                                slot: "default",
                              }),
                            ]
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                visible: _vm.dialogVisible,
                                modal: false,
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  src: _vm.dialogImageUrl,
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "policy margin10" }, [
                      _c("div", [_vm._v("奖励规则配置")]),
                      _c(
                        "div",
                        {
                          staticClass: "last",
                          on: {
                            click: function ($event) {
                              return _vm.dispalyAdd()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                            staticStyle: {
                              "font-size": "24px",
                              color: "#c34231",
                            },
                          }),
                          _c("span", [_vm._v("添加阶梯")]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            attrs: { data: _vm.displayladderTable, border: "" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "操作", width: "120" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delDisplayRow(
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "阶梯达成比例(%)",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入阶梯达成比例",
                                        },
                                        model: {
                                          value: scope.row.ratio,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "ratio", $$v)
                                          },
                                          expression: "scope.row.ratio",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "奖励类型", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.getDisplaySelectVal(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.rewardType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "rewardType",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.rewardType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.rewardTypeList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      scope.row.rewardType == "2"
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                color: "#c34231",
                                                "margin-top": "10px",
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openModal(
                                                    "displayProReward",
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 添加实物奖励 ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "奖励名称", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.rewardType == "1"
                                        ? _c("span", [_vm._v("现金奖励")])
                                        : scope.row.rewardType == "2"
                                        ? _c(
                                            "span",
                                            _vm._l(
                                              scope.row.giftProductVos,
                                              function (pitem, pindex) {
                                                return _c(
                                                  "el-tag",
                                                  {
                                                    key: pindex,
                                                    attrs: { closable: "" },
                                                    on: {
                                                      close: function ($event) {
                                                        return _vm.handleClose(
                                                          pitem,
                                                          "displayProReward",
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        pitem.productName || ""
                                                      )
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _c("span"),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "奖励周期(可多选)",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            multiple: "",
                                            clearable: "",
                                            placeholder: "请选择奖励周期",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.judgeDislpayCol(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.rewardPeriod,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "rewardPeriod",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.rewardPeriod",
                                          },
                                        },
                                        _vm._l(
                                          _vm.rewardCycle,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.label,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "季度达成奖励数量",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.displayladderTable[scope.$index]
                                        .quarterFlag
                                        ? _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "季度达成奖励数量",
                                            },
                                            model: {
                                              value: scope.row.quarterMoney,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "quarterMoney",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.quarterMoney",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "半年达成奖励数量",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.displayladderTable[scope.$index]
                                        .halfFlag
                                        ? _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "半年达成奖励数量",
                                            },
                                            model: {
                                              value: scope.row.halfYearMoney,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "halfYearMoney",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.halfYearMoney",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "年度达成奖励数量",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.displayladderTable[scope.$index]
                                        .allyearFlag
                                        ? _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "年度达成奖励数量",
                                            },
                                            model: {
                                              value: scope.row.allYearMoney,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "allYearMoney",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.allYearMoney",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.activeIndex == "2" || _vm.activeIndex == "3"
            ? _c("div", { staticClass: "proList margin10" }, [
                _c(
                  "div",
                  {
                    staticClass: "pro_label",
                    staticStyle: { width: "auto", cursor: "pointer" },
                  },
                  [
                    _vm._v(" 协议内容 "),
                    _c(
                      "span",
                      { staticClass: "prevArg", on: { click: _vm.getTemUrl } },
                      [_vm._v("预览协议内容")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "协议预览",
            size: "70%",
            height: _vm.dialogHeight,
            visible: _vm.dialogTableVisible,
            modal: true,
            "lock-scroll": true,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("pdf", {
            attrs: { src: _vm.tempUrl, page: _vm.curPage },
            on: { "num-pages": _vm.getPageNum },
          }),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button-group",
                { staticClass: "page" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-arrow-left",
                        disabled: _vm.curPage === 1,
                      },
                      on: { click: _vm.prevPage },
                    },
                    [_vm._v("上一页")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-arrow-right",
                        disabled:
                          !_vm.totalPage || _vm.curPage === _vm.totalPage,
                      },
                      on: { click: _vm.nextPage },
                    },
                    [_vm._v("下一页")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }