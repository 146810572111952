import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import addForm from '../form/add/index';
import detailsInfo from '../form/details/index';

export default {
  extends: TablePage,
  components: {
    addForm,
    detailsInfo,
  },
  name: 'config_list',
  data() {
    return {

    };
  },
  created() {
    this.getConfigList('page_list');
  },

  methods: {
    // 禁用
    modalClick({ val, row }) {
      console.log(val);
      console.log(row);
      if (val.code === 'enable') {
        if (!this.selection || this.selection.length === 0) {
          return this.$message.error('请至少选择一条数据');
        }
        request.post('/cps/cpsagreetemplate/enable', { ids: this.selection }).then((res) => {
          console.log(res);
          if (res.success) {
            this.$message.success(res.message);
            this.getList();
          }
        });
      }
      if (val.code === 'disable') {
        if (!this.selection || this.selection.length === 0) {
          return this.$message.error('请至少选择一条数据');
        }
        request.post('/cps/cpsagreetemplate/disable', { ids: this.selection }).then((res) => {
          console.log(res);
          if (res.success) {
            this.$message.success(res.message);
            this.getList();
          }
        });
      }
      if (val.code === 'add') {
        this.formName = 'addForm';
        this.modalConfig.title = '新增';
        this.formConfig = {
          code: val.code,
        };
        this.openFull();
      }
      if (val.code === 'view') {
        console.log('ffff');
        this.formName = 'detailsInfo';
        this.modalConfig.title = '查看';
        console.log(row.code);
        this.formConfig = {
          code: val.code,
          agreeCode: row.code,
        };
        this.openFull();
      }
    },
  },
};
